<div class="g-0">
    <div class="col">
        <div class="box-wrapper box-istoric">
            <div class="box-content-header">
                <div class="row p-1">
                    <div class="col">
                        <mat-card-title class="d-flex justify-content-between">
                            <span>
                                <i class="fa-regular fa-comments"></i>
                                <span> Istoric</span>
                            </span>

                            <span class="action-buttons" *ngIf="istoric?.length">
                                <!-- Exportă operator -->
                                <button class="btn btn-light d-flex justify-content-center align-items-center" (click)="exportConv(1)">
                                    <ng-container *ngIf="isLoadingOperator; else exportOperatorIcon">
                                        <mat-spinner diameter="20"></mat-spinner>
                                    </ng-container>
                                    <ng-template #exportOperatorIcon>
                                        <i class="fa-regular fa-file"></i>
                                    </ng-template>
                                    Exportă operator
                                </button>
                                <!-- Exportă cetățean -->
                                <button class="btn btn-light d-flex justify-content-center align-items-center" (click)="exportConv(2)">
                                    <ng-container *ngIf="isLoadingCitizen; else exportCitizenIcon">
                                        <mat-spinner diameter="20"></mat-spinner>
                                    </ng-container>
                                    <ng-template #exportCitizenIcon>
                                        <i class="fa-regular fa-file"></i>
                                    </ng-template>
                                    Exportă cetățean
                                </button>

                                <!-- Exportă tot -->
                                <button class="btn btn-light d-flex justify-content-center align-items-center" (click)="exportConv(3)">
                                    <ng-container *ngIf="isLoadingAll; else exportAllIcon">
                                        <mat-spinner diameter="20"></mat-spinner>
                                    </ng-container>
                                    <ng-template #exportAllIcon>
                                        <i class="fa-regular fa-file"></i>
                                    </ng-template>
                                    Exportă tot
                                </button>
                                <form [formGroup]="searchParamsForm">
                                    <mat-form-field>
                                        <mat-label>Tip mesaj</mat-label>
                                        <mat-select formControlName="messageType" (selectionChange)="filterHistoryByType()">
                                            <mat-option *ngFor="let message of messageTypes" [value]="message.value">
                                                {{ message.key }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </form>
                            </span>

                        </mat-card-title>
                    </div>
                </div>
            </div>
            <div class="box-content-body p-2">
                <div *ngFor="let ist of filteredHistory" class="box-message" [class.client]="ist.type == 0" [class.to-client]="ist.type == 1"
                    [class.intern]="ist.type == 2" [class.note]="ist.type == 3" [class.me]="user.id_user == ist.user_id && ist.type !== 0">
                    <div class="box-chat">

                        <div class="box-chat-header">
                            <span *ngIf="ist.type == 0">Răspuns UAT</span>
                            <span *ngIf="ist.type == 1">Mesaj către UAT</span>
                            <span *ngIf="ist.type == 2">Mesaj intern</span>
                            <span *ngIf="ist.type == 3">Notă</span>

                            <div class="box-chat-header-tool-box">
                                <div *ngIf="!isReportMessageLoading[ist.id]">
                                    <span class="me-2">
                                        <button *ngIf="ist.type == 1" type="button" class="btn btn-light" (click)="resendMessage(ist.id)">Retrimite mesaj</button>
                                    </span>
                                    <!-- <span class=" red-text" *ngIf="notifyIds.indexOf(ist.id) > -1">
                                        <i class="fa-solid fa-flag"></i>
                                    </span> -->
                                </div>
                                <mat-spinner *ngIf="isReportMessageLoading[ist.id]" diameter="30" color="primary"></mat-spinner>
                            </div>
                        </div>

                        <div class="message-content" [innerHTML]="ist.content"></div>
                        <!-- here to add files if present!!! -->
                        <div class="doc-attachment p-2" *ngIf="ist.documents.length > 0">
                            <button type="button" class="btn btn-light upload-btn m-2" *ngFor="let file of ist.documents" (click)="downloadDocument(file)">
                                <span class="upload__text">
                                    {{ file.old_name }}
                                </span>
                            </button>
                        </div>

                        <!-- Notifications -->
                        <div *ngIf="ist.notifications && ist.notifications.length && ist.type !== 0" class="notifications-container">
                            <div *ngFor="let notification of ist.notifications" class="d-flex justify-content-end notification-item">
                                <span class="p-2" *ngIf="ist.type !== 1">
                                    <i class="fa-regular fa-user"></i> {{notification.user_name}}
                                </span>
                                <span class="p-2" title="{{notification.processed_web}}" *ngIf="ist.type == 2">
                                    <i class="fa-solid fa-flag" [style.color]="notification.processed_web === null ? 'red' : 'grey'"></i>
                                </span>
                                <span class="p-2" *ngIf="ist.type !== 1" title="{{notification.processed_email_date}}">
                                    <i class="fa-solid fa-envelope" [style.color]="notification.processed_email_date === null ? 'red' : 'grey'"></i>
                                </span>
                            </div>
                        </div>

                        <!-- here to add coleagues if present -->
                    </div>
                    <div class="box-chat-footer">
                        <small *ngIf="ist.type !== 0">
                            <i class="fa-regular fa-user"></i> {{ist.user_name}}
                        </small>
                        <small *ngIf="ist.type == 0">
                            <i class="fa-regular fa-user"></i> {{ist.user_name}}
                        </small>
                        <small>
                            <i class="fa-regular fa-clock"></i>
                            {{ist.created_at}}
                        </small>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>