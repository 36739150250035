import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { PermissionsService } from 'src/app/services/permissions.service';
import { AdminSettingsSidebar } from 'src/app/utils/enum/sidebar-settings';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-admin-settings',
    templateUrl: './admin-settings.component.html',
    styleUrls: ['./admin-settings.component.scss'],
})
export class AdminSettingsComponent {
    readonly AdminSettingsSidebar = AdminSettingsSidebar;
    selectedComponent!: AdminSettingsSidebar;
    env = environment;
    filteredComponents: string[] = [];

    constructor(private permissionsService: PermissionsService) {
        this.filterComponents();
    }

    filterComponents() {
        this.filteredComponents = [...this.componentKeys];

        if (!this.permissionsService.hasPermission('administrative.configurations.list')) {
            this.filteredComponents = this.filteredComponents.filter(item => item !== AdminSettingsSidebar.CONFIGURARI)
        }
        if (!this.permissionsService.hasPermission('administrative.image-types.list')) {
            this.filteredComponents = this.filteredComponents.filter(item => item !== AdminSettingsSidebar.TYPE_IMAGE)
        }
        if (!this.permissionsService.hasPermission('administrative.user_types.list')) {
            this.filteredComponents = this.filteredComponents.filter(item => item !== AdminSettingsSidebar.TYPE_USER)
        }
        if (!this.permissionsService.hasPermission('administrative.crons.list')) {
            this.filteredComponents = this.filteredComponents.filter(item => item !== AdminSettingsSidebar.CRONS)
        }
    }

    componentMapping: Record<AdminSettingsSidebar, string> = {
        [AdminSettingsSidebar.CONFIGURARI]: 'app-config-admin',
        [AdminSettingsSidebar.TYPE_IMAGE]: 'app-type-image-admin',
        [AdminSettingsSidebar.TYPE_USER]: 'app-user-type',
        [AdminSettingsSidebar.CRONS]: 'app-crons',
    };
    componentKeys: string[] = Object.keys(this.componentMapping);

    onChange($event: { value: any }) {
        this.selectedComponent = $event.value;
    }

    selectedSection: any = null;
}
