import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { StorageService } from 'src/app/utils/storage/storage.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { MatDialog } from '@angular/material/dialog';
import { ResetPasswordComponent } from 'src/app/components/reset-password/reset-password.component';
import { PermissionsService } from 'src/app/services/permissions.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Subscription, finalize, interval, startWith, switchMap } from 'rxjs';

@Component({
    selector: 'navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
    @ViewChild('adminPopover') adminPopover!: PopoverDirective;
    @ViewChild('notificationsPopover') notificationsPopover!: PopoverDirective;
    @Output() selectionChange = new EventEmitter<string>();
    // Used to close the popover when clicking outside of the navbar component
    private globalClickListener!: () => void;
    isLoading = false;
    userNotifications!: string;
    cereriNotifications!: number;
    mesajeNotifications!: number;
    closePopover(): void {
        if (this.adminPopover) {
            this.adminPopover.hide();
            this.isPopoverOpen = false;
        }
    }

    closeNotificationPopover(): void {
        if (this.notificationsPopover) {
            this.notificationsPopover.hide();
            this.isNotificationsPopoverOpen = false;
        }
    }

    togglePopover() {
        if (!this.isPopoverOpen) {
            this.closeNotificationPopover();
        }
        this.isPopoverOpen = !this.isPopoverOpen;
    }

    toggleNotificationsPopover() {
        if (!this.isNotificationsPopoverOpen) {
            this.closePopover();
        }
        this.isNotificationsPopoverOpen = !this.isNotificationsPopoverOpen;

    }


    isPopoverOpen: boolean = false;
    isNotificationsPopoverOpen: boolean = false;

    id_user: number = 0;
    username: string = '';
    usernameLetters: string = '';
    memberSince!: Date;
    panelOpenState1 = false;
    panelOpenState2 = false;
    activeRoute = '';
    urbanismCereriNotifications!: string;
    urbanismMesajeNotifications!: string;
    // userNotifications = 0;
    public notification: { [key: string]: string } = {};

    public env: any;
    public isMenuOpen: boolean = false;
    private authRefreshSubscription!: Subscription;

    constructor(
        private store: StoreService,
        private storageService: StorageService,
        private authService: AuthService,
        public permissionsService: PermissionsService,
        private router: Router,
        private dialog: MatDialog,
        private notificationService: NotificationService,
        private elementRef: ElementRef,
        private renderer: Renderer2
    ) {
        this.activeRoute = router.url;
        this.env = environment;
    }

    ngOnInit(): void {
        this.initGlobalClickListener();
        this.getUserDetails();
        this.startCount();
    }

    ngOnDestroy(): void {
        if (this.globalClickListener) {
            this.globalClickListener();
        }
    }

    // Used to close the popover when clicking outside of the navbar component

    initGlobalClickListener() {
        this.globalClickListener = this.renderer.listen(
            'document',
            'click',
            (event) => {
                if (
                    this.notificationsPopover &&
                    !this.elementRef.nativeElement.contains(event.target)
                ) {
                    this.closeNotificationPopover();
                    this.closePopover();
                }
            }
        );
    }

    public onSidenavClick(): void {
        this.isMenuOpen = false;
    }

    onButtonClick(value: { id: string; name: string }) {
        this.closeNotificationPopover();
        this.notificationService.changeNotification(value);
        this.router.navigate(['/requests']);
    }

    getUserDetails() {
        var self = this;
        setTimeout(() => {
            const userObj = self.storageService.getObject(environment.config.userKey);
            userObj
                .then((val) => {
                    self.username = val.name;
                    self.memberSince = val.created_at;
                    self.extractLetters(self.username);
                })
                .catch((err) => {
                    self.username = 'Not logged in';
                    console.log(err);
                });
        }, 300);
    }

    extractLetters(username: string) {
        const firstLetters = username.split(' ').map((word) => word.charAt(0));
        this.usernameLetters = firstLetters.slice(0, 2).join('');
    }

    setActiveRoute(value: string) {
        this.activeRoute = value;
    }

    openResetPasswordComp() {
        this.closePopover();
        const dialogRef = this.dialog.open(ResetPasswordComponent, {
            minHeight: '45vh',
            maxHeight: '45vh',
            minWidth: '60vw',
            maxWidth: '60vw',
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe(({ success }: { success: boolean }) => {
            if (success) {
            }
        });
    }

    logout() {
        this.authService.logout().subscribe({});
        this.authService.clearStorage();
        this.permissionsService.resetUserPermissions();
        this.router.navigate(['/pages/login']);
    }

    startCount() {
        this.authRefreshSubscription = interval(120000) // 120,000 milliseconds = 2 minutes
            .pipe(
                startWith(0), // startwith 0 ca primul call sa fie facut de la 0, fara sa asteptam cele 2 minute de mai sus
                switchMap(() => {
                    this.isLoading = true;

                    return this.notificationService
                        .getNotifications()
                        .pipe(finalize(() => (this.isLoading = false)));
                })
            )
            .subscribe({
                next: (response: any) => {
                    if (response) {
                        const data = response;

                        let totalNotifications = 0;

                        Object.keys(data).forEach((key) => {
                            const value = data[key];
                            if (typeof value === 'number') {
                                this.notification[key] = value.toString();

                                totalNotifications += value;
                            }
                        });

                        this.userNotifications = totalNotifications.toString();
                    } else {
                        console.warn('Response data is undefined or null');
                    }

                    this.isLoading = false;
                },
                error: (error) => {
                    console.error('Error fetching notifications:', error);
                    this.isLoading = false;
                },
            });
    }
}
