import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { finalize, Subject } from 'rxjs';
import { DataTablesResponse } from 'src/app/interfaces/data-table-response.interface';
import { PermissionsService } from 'src/app/services/permissions.service';
import { environment } from 'src/environments/environment';
import { helper } from 'src/environments/helper';
import { NotificariService } from '../../notificari.service';
import Swal from 'sweetalert2';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-sms',
    templateUrl: './sms.component.html',
    styleUrls: ['./sms.component.scss']
})
export class SmsComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() searchParamsForm!: FormGroup;
    @ViewChild(DataTableDirective, { static: false })
    dtElement!: DataTableDirective;
    helper = helper;

    dtOptions: any = {};
    dtTrigger: Subject<any> = new Subject<any>();
    dtInstance!: DataTables.Api;
    isLoading = false;

    dtLink =
        environment.interop.basePath +
        environment.interop.platform.nomenclatoare.notificari.sms.getSMSDt;

    constructor(
        private http: HttpClient,
        private permissionsService: PermissionsService,
        private notificariService: NotificariService,
    ) { }

    ngOnInit(): void {
        this.renderDT();
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next('');
    }

    renderDT() {
        this.isLoading = true;
        this.dtOptions = {
            order: [[0, 'desc']],
            responsive: true,
            pageLength: 25,
            searching: false,
            lengthMenu: [10, 25, 50, 100],
            serverSide: true,
            ajax: (dataTablesParameters: any, callback: any) => {
                this.isLoading = true;

                const filters = { ...this.searchParamsForm.value };
                delete filters['email'];
                dataTablesParameters.filters = filters;

                this.http
                    .post<DataTablesResponse>(this.dtLink, dataTablesParameters)
                    .subscribe((resp: any) => {
                        this.isLoading = false;
                        callback({
                            recordsTotal: resp.data.recordsTotal,
                            recordsFiltered: resp.data.recordsFiltered,
                            data: resp.data.data,
                        });
                    });
            },
            language: environment.config.romanianLanguageDataTable,
            columns: [
                {
                    title: 'ID',
                    data: 'id',
                },
                {
                    title: 'Identificare Solicitare',
                    data: 'reg_number',
                    render: (data: any, type: any, row: any) => {
                        return `
                                <div style="display: flex; align-items: center; gap: 5px;">
                                    <span>${row.reg_number ? row.reg_number : 'N/A'}</span>
                                    <span>/</span>
                                    <span>${row.cerere_id}</span>
                                </div>
                            `;
                    },
                },
                {
                    title: 'Conținut',
                    data: 'content',
                },
                {
                    title: 'Trimis către',
                    data: 'sms_sent_to',
                    orderable: false,
                },
                {
                    title: 'Status',
                    data: 'sms_status',
                    orderable: false,
                },
                {
                    title: 'Creat',
                    data: 'created_at',
                    render: (data: any, type: any, row: any) => {
                        return `
                                <span>${this.formatDate(data)}</span>
                            `;
                    },
                },
                {
                    title: 'Actualizat',
                    data: 'updated_at',
                    render: (data: any, type: any, row: any) => {
                        return `
                                <span>${this.formatDate(data)}</span>
                            `;
                    },
                },
                {
                    title: 'Acțiuni',
                    data: null,
                    orderable: false,
                    className: 'all',
                    render: () => {
                        return `
			                <div class="d-flex align-center gap-1 justify-content-left">
                                <button class="action-btn edit resend">
                                    <span style="color: #fff;">RETRIMITE</span>
                                </button>
							</div>
						`;
                    },
                },
            ],
            rowCallback: (row: Node, data: any[] | any) => {
                const lastTd = (row as HTMLElement).querySelector('td:last-child');
                const resendButton = lastTd?.querySelector('.resend') as HTMLElement;

                if (resendButton) {
                    if (
                        !this.permissionsService.hasPermission(
                            'notificari.sms.resend'
                        )
                    ) {
                        resendButton.style.display = 'none';
                    }
                    resendButton.addEventListener('click', () => {
                        const { id } = data;
                        this.resendSms(id);
                    });
                }

                return row;
            },
        };
    }

    rerenderDT(paginate = false): void {
        this.isLoading = true;
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            this.isLoading = false;
            dtInstance.draw(paginate);
        });
    }

    formatDate(input: string): string {
        const [datePart, timePart] = input.split(' ');
        const [year, month, day] = datePart.split('-');

        return `${day}.${month}.${year} ${timePart}`;
    }

    resendSms(id: number) {
        Swal.fire({
            title: helper.dialogConfig.commonTitles.areYouSure,
            text: 'Doriți să retrimiteți sms-ul selectat?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: helper.dialogConfig.buttonLabels.close,
            confirmButtonColor: helper.dialogConfig.colorButtons.standard,
            confirmButtonText: helper.dialogConfig.buttonLabels.confirm,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                this.isLoading = true;
                this.notificariService.resendSms(id)
                    .pipe(finalize(() => (this.isLoading = false)))
                    .subscribe({
                        next: () => {
                            Swal.fire({
                                title: helper.dialogConfig.headers.success,
                                text: 'Notificare SMS trimisă cu succes.',
                                icon: 'success',
                                confirmButtonText: helper.dialogConfig.buttonLabels.close,
                                allowOutsideClick: false,
                            }).then(() => {
                                this.rerenderDT();
                            });
                        },
                        error: (err) => {
                            let msg;
                            try {
                                // try to catch the message from server
                                msg = err.error.errors.message[0];
                            } catch (error) {
                                // controll output message
                                msg = helper.dialogConfig.generalMessages.error;
                            }

                            Swal.fire({
                                title: helper.dialogConfig.headers.errorAtentie,
                                text: msg,
                                icon: 'success',
                                confirmButtonText: helper.dialogConfig.buttonLabels.retry,
                                showCancelButton: true,
                                cancelButtonText: helper.dialogConfig.buttonLabels.close,
                                reverseButtons: true,
                                allowOutsideClick: false,
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    this.resendSms(id);
                                }
                            });
                        }
                    })
            }
        })
    }
}
