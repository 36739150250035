<div class="sidenav" [ngClass]="{'collapsed-sidebar': isCollapsed, 'sidebar': !isCollapsed}">
	<mat-drawer-container class="drawer-container">
		<mat-drawer class="drawer side-bar-class" mode="side" opened>
			<div class="drawer__header">
				<div class="drawer__header--icon">
					<div class="hamburger-menu">
						<mat-icon class="chevron-button" [ngClass]="{'rotated': isCollapsed}"
							(click)="toggleSidebar()">chevron_left</mat-icon>
					</div>
					<img class="ps4-icon" src="assets/images/newAppLogo.svg" alt="Ministerul Cercetării, Inovării și Digitalizării" />
					<div class="drawer__header--text">
						<div class="drawer__header--text--title">{{appName}}</div>
						<div class="drawer__header--text--title--minWidth">MCID</div>
						<div class="drawer__header--text--subtitle faded-text">
							{{appDescription}}
						</div>
						<div class="drawer__header--text--subtitle--minWidth faded-text">
							PO
						</div>
					</div>
				</div>
			</div>
			<div class="drawer__buttons">
				<mat-accordion>

					<p *ngIf="permissionsService.hasPermission('solicitari.list')"
                        class="settings-option-menu" routerLink="/requests"
                        [class.menu-active]="'/requests' === activeOption" (click)="setActive('/requests')">
                        <img src="/assets/icons/cereri.svg" alt="" />
                        <span>Solicitări</span>
                    </p>
                    <p *ngIf="permissionsService.hasPermission('sabloane-cerere.list')"
                        class="settings-option-menu" routerLink="/sabloane-requests"
                        [class.menu-active]="'/sabloane-requests' === activeOption"
                        (click)="setActive('/sabloane-requests')">
                        <img src="/assets/icons/drawer.svg" alt="" />
                        <span>Șabloane Solicitări</span>
                    </p>
					<p *ngIf="permissionsService.hasPermission('galerie.list')" 
                        routerLink="/galerie" class="menu-link" [class.menu-active]="'/galerie' === activeOption"
						(click)="setActive('/galerie')">
						<img src="/assets/icons/gallery.svg" alt="" />
						<span>Galerie</span>
					</p>
					<p *ngIf="permissionsService.hasPermission('clients.list')"
                        routerLink="/clienti" class="menu-link" [class.menu-active]="'/clienti' === activeOption"
						(click)="setActive('/clienti')">
						<img src="/assets/icons/clients.svg" alt="" />
						<span>Instituții</span>
					</p>
                    <p *ngIf="permissionsService.hasPermission('uat.list')"
                        routerLink="/uat" class="menu-link" [class.menu-active]="'/uat' === activeOption"
						(click)="setActive('/uat')">
						<img src="/assets/icons/landmark.svg" alt="" />
						<span>UAT</span>
					</p>
                    <p *ngIf="permissionsService.hasPermission('centralizator.list')"
                        class="settings-option-menu" routerLink="/centralizator"
                        [class.menu-active]="'/centralizator' === activeOption"
                        (click)="setActive('/centralizator')">
                        <img src="/assets/icons/borderou.svg" alt="" />
                        <span>Centralizator</span>
                    </p>
                    <p *ngIf="permissionsService.hasPermission('raports.list')"
                        class="settings-option-menu" routerLink="/rapoarte"
                        [class.menu-active]="'/rapoarte' === activeOption"
                        (click)="setActive('/rapoarte')">
                        <img src="/assets/icons/rapoarte.svg" alt="" />
                        <span>Live Report</span>
                    </p>
                    <p *ngIf="permissionsService.hasPermission('notificari.list')"
                        class="settings-option-menu" routerLink="/notificari"
                        [class.menu-active]="'/notificari' === activeOption"
                        (click)="setActive('/notificari')">
                        <img src="/assets/icons/notificari.svg" alt="" />
                        <span>Notificări</span>
                    </p>
					<p routerLink="/settings" class="menu-link" [class.menu-active]="'/settings' === activeOption"
						(click)="setActive('/settings')">
						<img src="/assets/icons/settings.svg" alt="" />
						<span>Setări</span>
					</p>
					<mat-expansion-panel class="sidenav-accordion" (opened)="panelOpenState2 = true"
						(closed)="panelOpenState2 = false">
						<mat-expansion-panel-header class="sidenav-accordion" [class.hide-line]="panelOpenState2">
							<mat-panel-title class="sidenav-accordion">
								<img src="/assets/icons/administrative.svg" alt="" />
								<span>Administrativ</span>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<p class="settings-option-menu" routerLink="/admin-settings"
							[class.menu-active]="'/admin-settings' === activeOption"
							(click)="setActive('/admin-settings')">
							<img src="/assets/icons/settings.svg" alt="" />
							<span>Setări</span>
						</p>
					</mat-expansion-panel>
				</mat-accordion>

				<div class="footer">
					<div class="col-sm-12 px-2" [class.hidden]="isCollapsed == true">
						<small [innerHTML]="env.config.copyrightText"></small>
					</div>
					<div class="logout-button" (click)="logout()">
						<img src="assets/icons/logout.svg" alt="Deconectare" />
						<div class="logout-button--text">Deconectare</div>
					</div>
				</div>
			</div>
		</mat-drawer>
		<mat-drawer-content class="drawer-content" [style.marginLeft.px]="isCollapsed ? 85 : 280">
			<router-outlet></router-outlet>
		</mat-drawer-content>
	</mat-drawer-container>
</div>