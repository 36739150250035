import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestsService } from '../../requests.service';
import { TipuriDocumenteService } from '../../../../settings-sidenav/tipuri-documente/tipuri-documente.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { helper } from 'src/environments/helper';
import { finalize } from 'rxjs';
import { Generic } from 'src/app/interfaces/generic.interface';
import { NotificationService } from 'src/app/services/notification.service';
import { ToastrService } from 'ngx-toastr';
import { UploadDocumentPayload } from '../../request.interface';

@Component({
    selector: 'app-add-document-dialog',
    templateUrl: './add-document-dialog.component.html',
    styleUrls: ['./add-document-dialog.component.scss']
})
export class AddDocumentDialogComponent implements OnInit {
    // general error
    errorTitle: string = helper.dialogConfig.headers.errorAtentie;
    errorIcon: string = helper.dialogConfig.icons.error;
    errorType: string = helper.dialogConfig.icons.error;

    successTitle: string = helper.dialogConfig.headers.success;
    successIcon: string = helper.dialogConfig.icons.success;
    successType: string = helper.dialogConfig.icons.success;
    helper = helper;

    isLoading = false;
    form!: FormGroup;
    tipuriDocumente!: Generic[];
    uploading!: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any,
        private dialogRef: MatDialogRef<AddDocumentDialogComponent>,
        private requestService: RequestsService,
        private tipuriDocumenteService: TipuriDocumenteService,
        private fb: FormBuilder,
        private notificationService: NotificationService,
        public toastr: ToastrService,

    ) {
        this.form = this.fb.group({
            doc_type: new FormControl('', [Validators.required]),
            files: this.fb.array([], [Validators.required]),
        });
    }

    ngOnInit(): void {
        this.loadTipuriDocumente();
    }

    closeDialog() {
        this.dialogRef?.close({
            success: false,
        });
    }

    loadTipuriDocumente() {
        this.isLoading = true;
        this.tipuriDocumenteService.getTipuriDocumente()
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: (response) => {
                    this.tipuriDocumente = response;
                },
                error: (err) => {
                    console.log('Error in get tipuri documente: ', err);
                }
            })
    }

    addFile(event: any) {
        event.eventPreventDefault = true;
        const inputElement = event.target as HTMLInputElement;
        if (!inputElement.files) {
            return;
        }

        const files = inputElement.files;

        // Iterate through the selected files and append them to the 'files' array in the form
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            this.uploading = true;

            const formData = new FormData();
            formData.append('file', file, file.name);

            this.requestService.fileUpload(formData).subscribe({
                next: (result: any) => {
                    let response =
                        typeof result.status_code !== 'undefined' ? result : result.error;
                    console.log(result, 'response?');
                    if (typeof response.status_code !== 'undefined') {
                        if (
                            response.status_code == 200 &&
                            typeof response.data !== 'undefined'
                        ) {
                            // Handle the uploaded file by adding it to the 'files' array in the form
                            const files = this.form.get(
                                'files'
                            ) as FormArray;
                            files.push(new FormControl(result.data));

                            this.uploading = false;
                            this.openToast({
                                title: this.successTitle,
                                message:
                                    helper.dialogConfig.generalMessages.fileSuccessfullyUploaded,
                                type: this.successIcon,
                            });
                        }
                    } else {
                        // Handle error cases here
                        let errorMessage =
                            environment.config.customNotifications.generalMessages.error;
                        this.notificationService.warningSwal(
                            this.errorTitle,
                            errorMessage,
                            this.errorIcon
                        );
                        this.isLoading = false;
                        this.uploading = false;
                    }
                },
                error: (result: any) => {
                    this.isLoading = false;
                    this.uploading = false;
                    this.onHttpError(result.error);
                },
            });
        }
    }

    openToast(object: any) {
        const { type, message, title } = object;

        let options = this.toastr.toastrConfig;
        options.progressBar = true;
        options.preventDuplicates = true;
        options.closeButton = true;

        const inserted = this.toastr.show(
            message,
            title,
            options,
            options.iconClasses[type]
        );
    }

    onHttpError(res: any, showErrorsInToast: boolean = false) {
        if (res.errors && res.errors.message && res.errors.message.length > 0) {
            res.errors.message.forEach((msg: string) => {
                if (showErrorsInToast) {
                    this.openToast({
                        title: this.errorTitle,
                        message: msg,
                        type: this.errorType,
                    });
                } else {
                    this.notificationService.warningSwal(
                        this.errorTitle,
                        msg,
                        this.errorIcon
                    );
                }
            });
        } else {
            let errorMessage =
                environment.config.customNotifications.generalMessages.error;
            this.notificationService.warningSwal(
                this.errorTitle,
                errorMessage,
                this.errorIcon
            );
        }
    }

    removeFile(index: number) {
        const files = this.form.get('files') as FormArray;
        if (index > -1 && index < files.length) {
            files.removeAt(index);
        }
    }

    submitForm() {
        this.isLoading = true;

        const { doc_type, files } = this.form.value as UploadDocumentPayload;

        const addValue: UploadDocumentPayload = {
            doc_type,
            files,
        }

        this.requestService
            .uploadDocument(this.data, addValue)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.dialogRef.close({ success: true })
                },
                error: (err) => {
                    let msg;
                    try {
                        // try to catch the message from server
                        msg = err.error.errors.message[0];
                    } catch (error) {
                        // controll output message
                        msg = helper.dialogConfig.generalMessages.error;
                    }
                    this.notificationService.warningSwal(
                        helper.dialogConfig.headers.errorAtentie,
                        msg,
                        this.errorIcon
                    );
                }
            })
    }
}
