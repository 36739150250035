<div class="settings-card">
    <div class="settings-card__header">
        <img src="/assets/images/Group 24.svg" alt="" />
        <div class="settings-card__header-headline">
            <h2>Bine ați venit!</h2>
            <h4>Listă solicitări</h4>
        </div>
    </div>
</div>
<div class="requests-page">
    <div class="content-box">
        <div class="row">
            <div class="col-sm-12">
                <div class="section">
                    <div class="section-top">
                        <h3 class="section-title">Listă Solicitări</h3>
                        <div class="row">
                            <div class="col">
                                <button type="button" class="btn btn-sm btn-info pull-right ms-1" (click)="rerenderDT(false)">
                                    <i class="fa-solid fa-arrows-rotate"></i>
                                </button>
                                <button type="button" *ngIf="permissionsService.hasPermission('solicitari.export')"
                                    class="btn btn-sm btn-warning pull-right ms-1" (click)="exportCSV()">
                                    <i class="fa-solid fa-cloud-arrow-down"></i>
                                    Export
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-container request-filter p-2" *ngIf="showCard">
                        <form [formGroup]="searchParamsForm" (ngSubmit)="applyFilters()">

                            <div class="row">

                                <div class="col-md-6">
                                    <mat-form-field class="w-100">
                                        <mat-label>Direcție</mat-label>
                                        <mat-select formControlName="directieValue" (selectionChange)="onDirectieChange($event.value)">
                                            <mat-option *ngFor="let directie of directii" [value]="directie.id">
                                                {{ directie.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="col-md-6">
                                    <mat-form-field class="w-100">
                                        <mat-label>Compartiment</mat-label>
                                        <mat-select formControlName="compartimentValue">
                                            <mat-option [value]="-1">Toate</mat-option>
                                            <mat-option *ngFor="let compartiment of compartimenteActive$ | async" [value]="compartiment.id">
                                                {{ compartiment.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                            </div>

                            <div class="row">

                                <div class="col-md-6">
                                    <mat-form-field class="w-100">
                                        <mat-label>Tip Cerere</mat-label>
                                        <mat-select formControlName="tip_cerere">
                                            <mat-option value="-1">-- Alegeți --</mat-option>
                                            <mat-option *ngFor="let cerere of requestTypes" [value]="cerere.id">
                                                {{ cerere.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="col-md-6">
                                    <mat-form-field class="w-100">
                                        <mat-label>Eligibil</mat-label>
                                        <mat-select formControlName="validated">
                                            <mat-option *ngFor="let param of parameters" [value]="param.value">
                                                {{ param.key }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                
                            </div>

                            <div class="row">

                                <div class="col-md-6">
                                    <mat-form-field class="w-100">
                                        <mat-label>NE-Eligibil</mat-label>
                                        <mat-select formControlName="invalidated">
                                            <mat-option *ngFor="let param of parameters" [value]="param.value">
                                                {{ param.key }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="col-md-6">
                                    <mat-form-field class="w-100">
                                        <mat-label>Finalizare/Lucrare închisă</mat-label>
                                        <mat-select formControlName="finalizat_lucrare">
                                            <mat-option *ngFor="let param of parameters" [value]="param.value">
                                                {{ param.key }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                            </div>

                            <div class="row">

                                <div class="col-md-6">
                                    <mat-form-field class="w-100">
                                        <mat-label>Număr cerere</mat-label>
                                        <input matInput type="number" formControlName="numarCerere" />
                                    </mat-form-field>
                                </div>

                                <div class="col-md-6">
                                    <mat-form-field class="w-100">
                                        <mat-label>Număr cerere (intern)</mat-label>
                                        <input matInput type="number" formControlName="numarCerereIntern" />
                                    </mat-form-field>
                                </div>

                            </div>

                            <div class="row">

                                <div class="col-md-6">
                                    <mat-form-field class="w-100">
                                        <mat-label>COD SIRUTA</mat-label>
                                        <input matInput type="number" formControlName="siruta" />
                                    </mat-form-field>
                                </div>

                                <div class="col-md-6">
                                    <mat-form-field class="w-100">
                                        <mat-label>Denumire UAT</mat-label>
                                        <input type="text" matInput formControlName="denumire_uat"
                                            [matAutocomplete]="auto" />
                                        <mat-icon matSuffix class="opacity-50">search</mat-icon>
                                        <mat-autocomplete #auto="matAutocomplete">
                                            <mat-option *ngFor="let uat of searchedUats" [value]="uat.name"
                                                (onSelectionChange)="onUatSelected(uat)">
                                                {{ uat.name }}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>

                            </div>

                            <div class="row">

                                <div class="col-md-6">
                                    <mat-form-field class="w-100">
                                        <mat-label>Tip Beneficiar</mat-label>
                                        <mat-select formControlName="tipBeneficiar">
                                            <mat-option value="-1">-- Alegeți --</mat-option>
                                            <mat-option *ngFor="let beneficiar of beneficiari$ | async" [value]="beneficiar.id">
                                                {{ beneficiar.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="col-md-6">
                                    <mat-form-field class="w-100">
                                        <mat-label>Regiune</mat-label>
                                        <mat-select formControlName="regiune">
                                            <mat-option value="-1">-- Alegeți --</mat-option>
                                            <mat-option *ngFor="let regiune of regiuni" [value]="regiune.id">
                                                {{ regiune.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <mat-form-field class="w-100">
                                        <mat-label>Județ</mat-label>
                                        <mat-select formControlName="judet">
                                            <mat-option value="-1">-- Alegeți --</mat-option>
                                            <mat-option *ngFor="let row of judete" [value]="row.id">
                                                {{ row.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">

                                <div class="col-md-6">
                                    <mat-form-field class="w-100">
                                        <mat-label>Denumire Responsabil</mat-label>
                                        <input matInput type="text" formControlName="nume_reprezentant" />
                                    </mat-form-field>
                                </div>

                                <div class="col-md-6">
                                    <mat-form-field class="w-100">
                                        <mat-label>Email Responsabil</mat-label>
                                        <input matInput type="email" formControlName="email" />
                                    </mat-form-field>
                                </div>
                                
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <mat-form-field class="w-100">
                                        <mat-label>Evaluator Administrativ</mat-label>
                                        <input type="text" matInput formControlName="evaluatorSub" [matAutocomplete]="auto2" />
                                        <mat-icon matSuffix class="opacity-50">search</mat-icon>
                                        <mat-autocomplete #auto2="matAutocomplete">
                                            <mat-option *ngFor="let evaluator of filteredEvaluatori" [value]="evaluator.name"
                                                (onSelectionChange)="onEvaluatorChange(evaluator)">
                                                {{ evaluator.name }}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>

                                <div class="col-md-6">
                                    <mat-form-field class="w-100">
                                        <mat-label>Evaluator Tehnic</mat-label>
                                        <input type="text" matInput formControlName="evaluatorTechnicalSub" [matAutocomplete]="auto3" />
                                        <mat-icon matSuffix class="opacity-50">search</mat-icon>
                                        <mat-autocomplete #auto3="matAutocomplete">
                                            <mat-option *ngFor="let evaluatorT of filteredEvaluatoriTechnical" [value]="evaluatorT.name"
                                                (onSelectionChange)="onEvaluatorTechnicalChange(evaluatorT)">
                                                {{ evaluatorT.name }}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col d-flex justify-content-start">
                                    <button (click)="toggleCard()" type="button" class="btn btn-sm btn-warning closeBtn">
                                        Închide
                                    </button>
                                </div>
                                <div class="col buttons-submit">
                                    <button (click)="resetForm()" type="button" class="btn btn-sm btn-secondary">
                                        Resetează
                                    </button>
                                    <button type="submit" class="btn btn-sm btn-primary ms-2">
                                        Aplică
                                    </button>
                                </div>
                            </div>
                        </form>
                        <div class="section-spinner position-absolute" *ngIf="isFilterLoading">
                            <div class="spinner position-absolute top-50 start-50 translate-middle">
                                <mat-spinner></mat-spinner>
                                <h3 class="mt-2">
                                    {{ helper.dialogConfig.generalMessages.pleaseWait }}
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div class="row d-flex p-2">
                        <div class="col d-flex justify-content-start">
                            <button class="action-button d-flex align-items-center" (click)="toggleCard()" [class.active]="showCard">
                                <img src="assets/images/icons8-filter-edit-50.png" alt="" />
                                <span>Filtre</span>
                            </button>
                            <ng-container *ngIf="checkboxSelections">
                                <button *ngIf="permissionsService.hasPermission('solicitari.mass-assign')" 
                                    class="action-button d-flex align-items-center" (click)="openReassignComponent()">
                                    <span>Asignează</span>
                                </button>
                                <button *ngIf="permissionsService.hasPermission('solicitari.mass-update-status')" 
                                    class="action-button d-flex align-items-center" (click)="openChangeStatusComponent()">
                                    <span>Actualizare status</span>
                                </button>
                                <button *ngIf="permissionsService.hasPermission('solicitari.mass-update-priority')"  
                                    class="action-button d-flex align-items-center" (click)="openChangePrioritateComponent()">
                                    <span>Actualizare Prioritate</span>
                                </button>
                            </ng-container>
                        </div>
                        <div class="col d-flex justify-content-end">
                            <mat-chip-listbox *ngIf="notificationParameter.name" aria-label="Notification selection">
                                <mat-chip-option>
                                    <div class="d-flex justify-content-center align-items-center">
                                        <span class="chip-title">
                                            {{ notificationParameter.name }}
                                        </span>
                                        <span style="margin-left: 3px" (click)="removeChip()" aria-label="Remove">
                                            <mat-icon>cancel</mat-icon>
                                        </span>
                                    </div>
                                </mat-chip-option>
                            </mat-chip-listbox>

                            <button type="button" class="btn btn-light action-button" [popover]="popTemplate" [adaptivePosition]="true" container="body"
                                boundariesElement="viewport" placement="left">
                                <img src="assets/icons/calendar-icon.svg" alt="Calendar" />
                                <span>{{ selectedStartDate | date : "dd.MM.yyyy" }} - {{ selectedEndDate | date : "dd.MM.yyyy"
                                    }}
                                </span>
                            </button>

                            <!-- Popover Template below-->
                            <ng-template #popTemplate>
                                <div class="popover">
                                    <div class="row">
                                        <div class="col d-flex justify-content-between">
                                            <h2>Selectați intervalul</h2>
                                            <button style="height: 40px; width: 100px" class="btn btn-light close-btn" (click)="closePopover()">
                                                <img src="assets/icons/close.svg" alt="Închide" style="height: 20px; width: 20px" />
                                                <span class="close-text">ÎNCHIDE</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <h3>Filtrare după</h3>
                                            <select [(ngModel)]="dateFilterOption" (change)="onDateFilterChange()">
                                                <option *ngFor="let value of dateFilteringOptions" [value]="value">
                                                    {{ value }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row mt-2 mb-2">
                                        <div class="col d-flex justify-content-between responsive-filters">
                                            <button type="button" class="action-button" [class.selected]="
                          selectedFilteringButton === 'last_0_days'
                        " (click)="setLastXDays(0)">
                                                <span>AZI</span>
                                            </button>
                                            <button type="button" class="action-button" [class.selected]="
                          selectedFilteringButton === 'last_7_days'
                        " (click)="setLastXDays(7)">
                                                <span>7 ZILE</span>
                                            </button>
                                            <button type="button" class="action-button" [class.selected]="
                          selectedFilteringButton === 'last_30_days'
                        " (click)="setLastXDays(30)">
                                                <span>30 ZILE</span>
                                            </button>
                                            <button type="button" class="action-button" [class.selected]="
                          selectedFilteringButton === 'current_year'
                        " (click)="setYear(0)">
                                                <span>AN CURENT</span>
                                            </button>
                                            <button type="button" class="action-button" [class.selected]="
                          selectedFilteringButton === 'next_year'
                        " (click)="setYear(1)">
                                                <span>AN ANTERIOR</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="datepicker-container">
                                        <div class="col">
                                            <div class="datepicker-header">
                                                <div class="text">DE LA:</div>
                                                <div class="date">
                                                    {{ selectedStartDate | date : "mediumDate" }}
                                                </div>
                                            </div>
                                            <mat-card>
                                                <mat-calendar #startCalendar [(selected)]="selectedStartDate" [maxDate]="today" [maxDate]="selectedEndDate" (selectedChange)="rerenderDT()"></mat-calendar>
                                            </mat-card>
                                        </div>
                                        <div class="col">
                                            <div class="datepicker-header">
                                                <div class="text">PÂNĂ LA:</div>
                                                <div class="date">
                                                    {{ selectedEndDate | date : "mediumDate" }}
                                                </div>
                                            </div>
                                            <mat-card>
                                                <mat-calendar #endCalendar [(selected)]="selectedEndDate" [maxDate]="today" (selectedChange)="rerenderDT()"></mat-calendar>
                                            </mat-card>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <div class="request-filter-container">
                        <div class="request-filter">
                            <mat-button-toggle-group name="fontStyle" aria-label="Font Style" (change)="onStatusChange($event)" [value]="selectedStatusID">
                                <mat-button-toggle *ngFor="let status of statusCerere" [value]="status.id">
                                    {{ status.name }}
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>

                        <div class="request-filter">
                            <mat-button-toggle-group name="fontStyle" aria-label="Font Style" (change)="onCategoryChange($event)" [value]="selectedCategory">
                                <mat-button-toggle *ngFor="let option of categoryToggleOptions" [value]="option.id">
                                    {{ option.value}}
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>

                    <div class="table-container">
                        <table id="datatable" class="row-border hover custom-dt w-100" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"></table>
                    </div>
                    <div class="section-spinner position-absolute" *ngIf="isLoading">
                        <div class="spinner position-absolute top-50 start-50 translate-middle">
                            <mat-spinner></mat-spinner>
                            <h3 class="mt-2">
                                {{ helper.dialogConfig.generalMessages.pleaseWait }}
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>