import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Status, StatusEditPayload } from './cerere-status.interface';
import { catchError, map, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CerereStatusService {
    private token!: string;
    private httpOptions: any;
    private basePath = environment.interop.basePath;
    private getStatusUrl =
        this.basePath +
        environment.interop.platform.nomenclatoare.cerereStatus.getCerereStatuses;
    private getStatusDTUrl =
        this.basePath +
        environment.interop.platform.nomenclatoare.cerereStatus.getCerereStatusesDT;
    private storeStatusURL =
        this.basePath +
        environment.interop.platform.nomenclatoare.cerereStatus.storeCerereStatus;

    private deleteStoreStatus =
        this.basePath +
        environment.interop.platform.nomenclatoare.cerereStatus.deleteCerereStatus;

    private getStatusActive =
        this.basePath +
        environment.interop.platform.nomenclatoare.cerereStatus
            .getCerereStatusesActive;

    private getStatusPublic =
        this.basePath +
        environment.interop.platform.nomenclatoare.cerereStatus
            .getCerereStatusesActivePublic;

    private updateStatusStore =
        this.basePath +
        environment.interop.platform.nomenclatoare.cerereStatus.putCerereStatus;

    private updateOrderUrl =
        this.basePath +
        environment.interop.platform.nomenclatoare.cerereStatus.updateOrder;


    private changeStatusActiveUrl =
        this.basePath +
        environment.interop.platform.nomenclatoare.cerereStatus
            .changeStatusCerereStatusActive;
    private changeStatusPublicUrl =
        this.basePath +
        environment.interop.platform.nomenclatoare.cerereStatus
            .changeStatusCerereStatusPublic;

    constructor(private http: HttpClient) { }

    getStatusDT() {
        return this.http.get(this.getStatusDTUrl, this.httpOptions);
    }

    addStatus(data: Status) {
        return this.http.post(this.storeStatusURL, data, this.httpOptions);
    }

    getActiveStatus() {
        return this.http
            .get<{ errors: boolean; data: any[]; status_code: number }>(this.getStatusActive, this.httpOptions)
            .pipe(
                map((response: any) =>
                    response.data.map((item: any) => ({
                        id: item.id,
                        name: item.name,
                    }))
                ),
                catchError((error) => {
                    console.error('Error in get request statuses:', error);
                    return throwError(error);
                })
            );
    }

    getPublicStatus() {
        return this.http
            .get<{ errors: boolean; data: any[]; status_code: number }>(this.getStatusPublic, this.httpOptions)
            .pipe(
                map((response: any) =>
                    response.data.map((item: any) => ({
                        id: item.id,
                        name: item.name,
                    }))
                ),
                catchError((error) => {
                    console.error('Error in get request priorities:', error);
                    return throwError(error);
                })
            );
    }

    updateStatus(updateStatus: StatusEditPayload) {
        return this.http.put(
            this.updateStatusStore,
            updateStatus,
            this.httpOptions
        );
    }

    deleteStatus(id: number) {
        return this.http.delete(this.deleteStoreStatus + id);
    }

    getStatuses() {
        return this.http
            .get<{ errors: boolean; data: any[]; status_code: number }>(
                this.getStatusUrl
            )
            .pipe(
                map((response) =>
                    response.data.map((item) => ({
                        id: item.id,
                        name: item.name,
                    }))
                ),
                catchError((error) => {
                    console.error('Error in get request statuses:', error);
                    return throwError(error);
                })
            );
    }


    setToken(token: string) {
        this.token = token;
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.token}`,
            }),
        };
    }

    updateOrder(id: number, data: any) {
        return this.http.put(this.updateOrderUrl + id, data, this.httpOptions);
    }

    changeStatuseActive(id: number) {
        return this.http.put(this.changeStatusActiveUrl + id, {}, this.httpOptions);
    }

    changeStatusePublic(id: number) {
        return this.http.put(this.changeStatusPublicUrl + id, {}, this.httpOptions);
    }
}
