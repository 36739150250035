import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent {
    panelOpenState1 = false;
    panelOpenState2 = false;
    activeOption = '';
    isCollapsed = false;

    public isMenuOpen: boolean = false;

    public env: any

    appName = environment.config.appName;
    appDescription = environment.config.appDescription;

    constructor(
        private authService: AuthService,
        private router: Router,
        public permissionsService: PermissionsService,
    ) {
        this.activeOption = router.url;
        this.onResize();
        this.env = environment
    }

    setActive(value: string) {
        this.activeOption = value;
    }

    logout() {
        this.authService.logout().subscribe({});
        this.authService.clearStorage();
        this.permissionsService.resetUserPermissions();
        this.router.navigate(['/pages/login']);
    }

    toggleSidebar() {
        this.isCollapsed = !this.isCollapsed;
    }

    public onSidenavClick($event: any): void {
        this.isMenuOpen = false;
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.isCollapsed = window.innerWidth < 960;
    }
}
