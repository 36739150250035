import { HttpClient } from '@angular/common/http';
import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';
import { DialogService } from 'src/app/utils/services/dialog.service';
import { environment } from 'src/environments/environment';

import { DataTablesResponse } from 'src/app/interfaces/data-table-response.interface';
import { AddJudetComponent } from './add-judet/add-judet.component';
import Swal from 'sweetalert2';
import { EditJudetComponent } from './edit-judet/edit-judet.component';

import { helper } from 'src/environments/helper';
import { JudeteService } from './judete.service';
import { JudetUpdate } from './judet.interface';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
    selector: 'app-judete',
    templateUrl: './judete.component.html',
    styleUrls: ['./judete.component.scss'],
})
export class JudeteComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(DataTableDirective, { static: false })
    dtElement!: DataTableDirective;
    helper = helper;

    isLoading = false;
    showCard = false;
    activeValue: string = '-1';
    publicValue: string = '-1';

    dtOptions: any = {};
    dtTrigger: Subject<any> = new Subject<any>();
    dtInstance!: DataTables.Api;

    parameters: { key: string; value: string }[] = [
        { key: '--Alegeți--', value: '-1' },
        { key: 'Da', value: '0' },
        { key: 'Nu', value: '1' },
    ];

    resetForm() {
        this.activeValue = '-1';
        this.publicValue = '-1';

        this.rerenderDT();
        this.showCard = true;
    }

    applyFilters(): void {
        this.rerenderDT(true);
    }

    toggleCard() {
        this.showCard = !this.showCard;
    }

    dtLink =
        environment.interop.basePath +
        environment.interop.platform.nomenclatoare.judete.getJudeteDT;

    constructor(
        private http: HttpClient,
        private dialog: MatDialog,
        private dialogService: DialogService,
        private judetService: JudeteService,
        public permissionsService: PermissionsService,
    ) { }

    ngOnInit(): void {
        this.renderDT();
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next('');
    }

    renderDT() {
        this.isLoading = true;
        this.dtOptions = {
            pagingType: 'full_numbers',
            responsive: true,
            pageLength: 25,
            searching: false,
            lengthMenu: [10, 25, 50, 100],
            serverSide: true,
            ajax: (dataTablesParameters: any, callback: any) => {
                this.isLoading = true;
                dataTablesParameters.active = this.activeValue;
                dataTablesParameters.public_visible = this.publicValue;

                this.http
                    .post<DataTablesResponse>(
                        this.dtLink, // datatable link, we need this
                        dataTablesParameters // parameters for filtering, send here
                    )
                    .subscribe((resp: any) => {
                        this.isLoading = false;
                        callback({
                            recordsTotal: resp.data.recordsTotal,
                            recordsFiltered: resp.data.recordsFiltered,
                            data: resp.data.data,
                        });
                    });
            },
            language: environment.config.romanianLanguageDataTable,
            columns: [
                {
                    title: 'ID',
                    data: 'id',
                },
                {
                    title: 'Nume',
                    data: 'name',
                },
                {
                    title: 'Regiune',
                    data: 'region_name',
                },
                {
                    title: 'Acțiuni',
                    data: null,
                    orderable: false,
                    className: 'all',
                    render: (data: any, type: any, row: any, met: any) => {
                        return (
                            `
			  <div class="d-flex align-left justify-content-left">
								<button type="button" class="action-btn edit ms-1">
									<img class="btn-img" src="/assets/icons/edit.svg" alt="edit"/>
								</button>
								<button type="button" class="action-btn delete ms-1">
									<img class="btn-img" src="/assets/icons/delete.svg" alt="delete"/>
								</button>
								<button type="button" class="action-btn update_status_active ms-1 ` +
                            (row.active == 1 ? 'action-success' : 'action-danger') +
                            `">
									<img class="btn-img" src="` +
                            (row.active == 1
                                ? '/assets/icons/check-white.svg'
                                : '/assets/icons/close-white.svg') +
                            `" alt="update_status_active"/>
								</button>
								</div>
								`
                        );
                    },
                },
            ],
            rowCallback: (row: Node, data: any[] | any) => {
                const lastTd = (row as HTMLElement).querySelector('td:last-child');
                const editIcon = lastTd?.querySelector('.edit') as HTMLElement;
                const deleteIcon = lastTd?.querySelector('.delete') as HTMLElement;
                const updateStatusActiveIcon = lastTd?.querySelector(
                    '.update_status_active'
                ) as HTMLElement;

                if (editIcon) {
                    if (
                        !this.permissionsService.hasPermission(
                            'settings.judet.update'
                        )
                    ) {
                        editIcon.style.display = 'none';
                    }
                    editIcon.addEventListener('click', () => {
                        this.openEditDialog(data);
                    });
                }

                if (deleteIcon) {
                    if (
                        !this.permissionsService.hasPermission(
                            'settings.judet.delete'
                        )
                    ) {
                        deleteIcon.style.display = 'none';
                    }
                    deleteIcon.addEventListener('click', () => {
                        const { id } = data;
                        this.deleteJudet(id);
                    });
                }

                if (updateStatusActiveIcon) {
                    if (
                        !this.permissionsService.hasPermission(
                            'settings.judet.update-status'
                        )
                    ) {
                        updateStatusActiveIcon.style.display = 'none';
                    }
                    updateStatusActiveIcon.addEventListener('click', () => {
                        const { id } = data;
                        this.changeStatusActive(id);
                    });
                }

                return row;
            },
        };
    }

    changeStatusActive(id: number) {
        Swal.fire({
            title: helper.dialogConfig.commonTitles.areYouSure,
            text: 'O sa actualizați statusul activ/inactiv aferent înregistrării selectate',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: helper.dialogConfig.buttonLabels.close,
            confirmButtonColor: helper.dialogConfig.colorButtons.standard,
            confirmButtonText: helper.dialogConfig.buttonLabels.confirm,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                this.changeStatusActiveAction(id);
            }
        });
    }

    changeStatusActiveAction(id: number) {
        this.judetService.changeStatusActive(id).subscribe(
            () => {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'Înregistrare actualizată cu succes!',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            },
            (err) => {
                let msg;
                try {
                    // try to catch the message from server
                    msg = err.error.errors.message[0];
                } catch (error) {
                    // controll output message
                    msg = helper.dialogConfig.generalMessages.error;
                }
                this.rerenderDT();
                Swal.fire({
                    title: helper.dialogConfig.headers.errorAtentie,
                    text: msg,
                    icon: 'error',
                    confirmButtonText: helper.dialogConfig.buttonLabels.retry,
                    showCancelButton: true,
                    cancelButtonText: helper.dialogConfig.buttonLabels.close,
                    reverseButtons: true,
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.changeStatusActiveAction(id);
                    }
                });
            }
        );
    }

    rerenderDT(resetPaging = false): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw(resetPaging);
        });
        this.showCard = false;
    }

    openAddDialog() {
        const dialogRef = this.dialog.open(AddJudetComponent, {
            ...this.dialogService.getDialogConfig(),
        });
        dialogRef.afterClosed().subscribe(({ success }: { success: boolean }) => {
            if (success) {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'Județ adăugat cu succes!',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            }
        });
    }

    openEditDialog(data: JudetUpdate) {
        const dialogRef = this.dialog.open(EditJudetComponent, {
            ...this.dialogService.getDialogConfig(),
            data,
        });

        dialogRef.afterClosed().subscribe(({ success }: { success: boolean }) => {
            if (success) {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'Județ actualizat cu succes!',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            }
        });
    }

    deleteJudet(id: number) {
        Swal.fire({
            title: helper.dialogConfig.commonTitles.areYouSure,
            text: 'Nu o să puteți recupera datele odată cu ștergerea lor!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: helper.dialogConfig.colorButtons.standard,
            confirmButtonText: helper.dialogConfig.buttonLabels.confirm,
            cancelButtonText: helper.dialogConfig.buttonLabels.close,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                // we do this because we need to have some control on activity
                this.deleteAction(id);
            }
        });
    }

    deleteAction(id: number) {
        this.judetService.deleteJudet(id).subscribe(
            () => {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'Județ șters cu succes!',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            },
            (err) => {
                let msg;
                try {
                    // try to catch the message from server
                    msg = err.error.errors.message[0];
                } catch (error) {
                    // controll output message
                    msg = helper.dialogConfig.generalMessages.error;
                }

                Swal.fire({
                    title: helper.dialogConfig.headers.errorAtentie,
                    text: msg,
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.retry,
                    showCancelButton: true,
                    cancelButtonText: helper.dialogConfig.buttonLabels.close,
                    reverseButtons: true,
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.deleteAction(id);
                    }
                });
            }
        );
    }
}
