import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataTableDirective } from 'angular-datatables';
import { finalize, map, Observable, Subject } from 'rxjs';
import { DialogService } from 'src/app/utils/services/dialog.service';
import { environment } from 'src/environments/environment';
import { UatService } from './uat.service';
import { DataTablesResponse } from 'src/app/interfaces/data-table-response.interface';
import { AddUatComponent } from './add-uat/add-uat.component';
import { EditUatComponent } from './edit-uat/edit-uat.component';
import Swal from 'sweetalert2';
import { helper } from 'src/environments/helper';
import { PermissionsService } from 'src/app/services/permissions.service';
import { FormControl, FormGroup } from '@angular/forms';
import { JudeteService } from '../settings-sidenav/judete/judete.service';
import { AppFilesService } from 'src/app/services/app-files.service';

@Component({
    selector: 'app-uat',
    templateUrl: './uat.component.html',
    styleUrls: ['./uat.component.scss']
})
export class UatComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(DataTableDirective, { static: false })
    dtElement!: DataTableDirective;
    helper = helper;

    isLoading = false;
    isFilterLoading = false;
    showCard = false;
    activeValue: string = '-1';
    publicValue: string = '-1';

    searchParamsForm!: FormGroup;
    judete$!: Observable<any[]>;

    dtOptions: any = {};
    dtTrigger: Subject<any> = new Subject<any>();
    dtInstance!: DataTables.Api;
    dtParameters!: any;

    dtLink =
        environment.interop.basePath +
        environment.interop.platform.nomenclatoare.uat.getUatsDt;

    defaultParameter: { name: string; id: string } = {
        name: '-- Alegeți --',
        id: '-1',
    };

    parameters: { key: string; value: string }[] = [
        { key: '--Alegeți--', value: '-1' },
        { key: 'Da', value: '1' },
        { key: 'Nu', value: '0' },
    ];

    initialFormValues = {
        siruta: '',
        judet: '-1',
    };

    buildSearchParamsForm() {
        this.searchParamsForm = new FormGroup({
            siruta: new FormControl(''),
            judet: new FormControl('-1'),
        });
    }

    resetForm() {
        this.activeValue = '-1';
        this.publicValue = '-1';

        this.searchParamsForm.reset(this.initialFormValues);

        this.rerenderDT();
        this.showCard = true;
    }

    applyFilters(): void {
        this.rerenderDT(true);
    }

    toggleCard() {
        this.showCard = !this.showCard;
    }

    constructor(
        private dialogService: DialogService,
        private http: HttpClient,
        private dialog: MatDialog,
        private uatService: UatService,
        private judeteService: JudeteService,
        public permissionsService: PermissionsService,
        private appFilesService: AppFilesService,
    ) { }

    ngOnInit(): void {
        this.renderDT();
        this.buildSearchParamsForm();
        this.loadJudete();
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next('');
    }

    renderDT() {
        this.isLoading = true;
        this.dtOptions = {
            serverSide: true,
            searching: false,
            responsive: true,
            pageLength: 25,

            ajax: (dataTablesParameters: any, callback: any) => {
                dataTablesParameters.active = this.activeValue;
                dataTablesParameters.public_visible = this.publicValue;
                dataTablesParameters.filters = this.searchParamsForm.value;

                this.dtParameters = dataTablesParameters;

                this.isLoading = true;
                this.http
                    .post<DataTablesResponse>(this.dtLink, dataTablesParameters)
                    .subscribe((resp: any) => {
                        this.isLoading = false;
                        callback({
                            recordsTotal: resp.data.recordsTotal,
                            recordsFiltered: resp.data.recordsFiltered,
                            data: resp.data.data,
                        });
                    });
            },
            language: environment.config.romanianLanguageDataTable,
            columns: [
                {
                    title: 'ID',
                    data: 'id',
                },
                {
                    title: 'Cod SIRUTA',
                    data: 'siruta',
                },
                {
                    title: 'UAT',
                    data: 'name',
                },
                {
                    title: 'Județ',
                    data: 'judet_name',
                },
                {
                    title: 'Tip Beneficiar',
                    data: 'tip_beneficiar_nume',
                },
                {
                    title: 'Acțiuni',
                    data: null,
                    className: 'all',
                    orderable: false,
                    render: (row: any) => {
                        return `
                            <div class="d-flex align-center justify-content-left">
                                <button class="action-btn edit ms-1">
                                    <img class="btn-img" src="../../../../assets/icons/edit.svg" alt="edit"/>
                                </button>
                                <button class="action-btn delete ms-1">
                                    <img class="btn-img" src="../../../../assets/icons/delete.svg" alt="delete"/>
                                </button>
                                <button type="button" class="action-btn update_status_active ms-1 ` +
                            (row.active == true ? 'action-success' : 'action-danger') +
                            `">
                                        <img class="btn-img" src="` +
                            (row.active == true
                                ? '/assets/icons/check-white.svg'
                                : '/assets/icons/close-white.svg') +
                            `" alt="update_status_active"/>
                                </button>
                                <button type="button" class="action-btn update_status_public ms-1 ` +
                            (row.public_visible == true
                                ? 'action-success'
                                : 'action-danger') +
                            `">
                                    <img class="btn-img" src="` +
                            (row.public_visible == true
                                ? '/assets/icons/public_white.svg'
                                : '/assets/icons/public_not_white.svg') +
                            `" alt="update_status_public"/>
                                </button>
                            </div>
                        `;
                    },
                },
            ],
            rowCallback: (row: Node, data: any[] | any) => {
                const lastTd = (row as HTMLElement).querySelector('td:last-child');
                const editIcon = lastTd?.querySelector('.edit') as HTMLElement;
                const deleteIcon = lastTd?.querySelector('.delete') as HTMLElement;
                const updateStatusActiveIcon = lastTd?.querySelector(
                    '.update_status_active'
                ) as HTMLElement;
                const updateStatusPublicIcon = lastTd?.querySelector(
                    '.update_status_public'
                ) as HTMLElement;

                if (editIcon) {
                    if (!this.permissionsService.hasPermission('uat.update')) {
                        editIcon.style.display = 'none';
                    }
                    editIcon.addEventListener('click', () => {
                        this.openEditDialog(data);
                    });
                }

                if (deleteIcon) {
                    if (!this.permissionsService.hasPermission('uat.delete')) {
                        deleteIcon.style.display = 'none';
                    }
                    deleteIcon.addEventListener('click', () => {
                        const { id } = data;
                        this.deleteCerere(id);
                    });
                }

                if (updateStatusActiveIcon) {
                    if (!this.permissionsService.hasPermission('uat.update-status')) {
                        updateStatusActiveIcon.style.display = 'none';
                    }
                    updateStatusActiveIcon.addEventListener('click', () => {
                        const { id } = data;
                        this.changeStatusActive(id);
                    });
                }

                if (updateStatusPublicIcon) {
                    if (!this.permissionsService.hasPermission('uat.update-status')) {
                        updateStatusPublicIcon.style.display = 'none';
                    }
                    updateStatusPublicIcon.addEventListener('click', () => {
                        const { id } = data;
                        this.changeStatusPublic(id);
                    });
                }

                return row;
            },
        };
    }

    loadJudete() {
        this.isFilterLoading = true;
        this.judete$ = this.judeteService
            .getJudeteActive()
            .pipe(
                map((response: any) => [this.defaultParameter, ...response]),
                finalize(() => (this.isFilterLoading = false))
            );
    }

    rerenderDT(paginate: boolean = false): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw(paginate);
        });
        this.showCard = false;
    }

    openAddDialog() {
        const dialogRef = this.dialog.open(AddUatComponent, {
            ...this.dialogService.getDialogConfig(),
        });
        dialogRef.afterClosed().subscribe(({ success }: { success: boolean }) => {
            if (success) {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'UAT adăugat cu succes.',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            }
        });
    }

    openEditDialog(data: any) {
        const dialogRef = this.dialog.open(EditUatComponent, {
            ...this.dialogService.getDialogConfig(),
            data,
        });

        dialogRef.afterClosed().subscribe(({ success }: { success: boolean }) => {
            if (success) {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'UAT actualizat cu succes.',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            }
        });
    }

    deleteCerere(id: number) {
        Swal.fire({
            title: 'Sunteți sigur?',
            text: 'Nu o să puteți recupera datele odată cu ștergerea lor!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Confirm!',
            cancelButtonText: 'Cancel',
            showLoaderOnConfirm: true,
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                this.uatService.deleteUat(id).subscribe(() => {
                    Swal.fire({
                        title: 'Șters!',
                        text: 'UAT șters cu succes.',
                        icon: 'success',
                        confirmButtonText: 'OK',
                    }).then(() => {
                        this.rerenderDT();
                    });
                });
            }
        });
    }

    changeStatusActive(id: number) {
        Swal.fire({
            title: helper.dialogConfig.commonTitles.areYouSure,
            text: 'O să actualizați statusul UAT-ului selectat!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: helper.dialogConfig.buttonLabels.close,
            confirmButtonColor: helper.dialogConfig.colorButtons.standard,
            confirmButtonText: helper.dialogConfig.buttonLabels.confirm,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                this.changeStatusActiveAction(id);
            }
        });
    }

    changeStatusActiveAction(id: number) {
        this.uatService.changeStatuseActive(id).subscribe(
            () => {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'UAT actualizat cu succes.',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            },
            (err) => {
                let msg;
                try {
                    // try to catch the message from server
                    msg = err.error.errors.message[0];
                } catch (error) {
                    // controll output message
                    msg = helper.dialogConfig.generalMessages.error;
                }
                this.rerenderDT();
                Swal.fire({
                    title: helper.dialogConfig.headers.errorAtentie,
                    text: msg,
                    icon: 'error',
                    confirmButtonText: helper.dialogConfig.buttonLabels.retry,
                    showCancelButton: true,
                    cancelButtonText: helper.dialogConfig.buttonLabels.close,
                    reverseButtons: true,
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.changeStatusActiveAction(id);
                    }
                });
            }
        );
    }

    changeStatusPublic(id: number) {
        Swal.fire({
            title: helper.dialogConfig.commonTitles.areYouSure,
            text: 'O să actualizați statusul UAT-ului selectat!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: helper.dialogConfig.buttonLabels.close,
            confirmButtonColor: helper.dialogConfig.colorButtons.standard,
            confirmButtonText: helper.dialogConfig.buttonLabels.confirm,
            showLoaderOnConfirm: true,
            reverseButtons: true,
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                this.changeStatusPublicAction(id);
            }
        });
    }

    changeStatusPublicAction(id: number) {
        this.uatService.changeStatusePublic(id).subscribe(
            () => {
                Swal.fire({
                    title: helper.dialogConfig.headers.success,
                    text: 'UAT actualizat cu succes.',
                    icon: 'success',
                    confirmButtonText: helper.dialogConfig.buttonLabels.close,
                    allowOutsideClick: false,
                }).then(() => {
                    this.rerenderDT();
                });
            },
            (err) => {
                let msg;
                try {
                    // try to catch the message from server
                    msg = err.error.errors.message[0];
                } catch (error) {
                    // controll output message
                    msg = helper.dialogConfig.generalMessages.error;
                }
                this.rerenderDT();
                Swal.fire({
                    title: helper.dialogConfig.headers.errorAtentie,
                    text: msg,
                    icon: 'error',
                    confirmButtonText: helper.dialogConfig.buttonLabels.retry,
                    showCancelButton: true,
                    cancelButtonText: helper.dialogConfig.buttonLabels.close,
                    reverseButtons: true,
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.changeStatusPublicAction(id);
                    }
                });
            }
        );
    }

    exportCSV() {
        this.isLoading = true;
        this.appFilesService.downloadCsvFile(
            this.uatService.exportCSVUrl,
            this.dtParameters,
            'uat',
            (loading: boolean) => {
                this.isLoading = loading;
            }
        );
    }
}
