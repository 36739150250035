import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModuleModule } from './auth-module/auth-module.module';
import { PagesLayoutModule } from './layout/pages-layout/pages-layout.module';
import { HttpClientModule } from '@angular/common/http';
import { LoginModule } from './auth-module/login/login.module';
import { AlertDialogModule } from './utils/alert-dialog/alert-dialog.module';
import { LayoutModule } from './layout/layout/layout.module';
import { SettingsSidenavModule } from './layout/pages-layout/nomenclatoare/settings-sidenav/settings-sidenav.module';
import { AdministrationModule } from './layout/pages-layout/nomenclatoare/administration/administration.module';
import { CetateanModule } from './layout/pages-layout/nomenclatoare/cetatean/cetatean.module';
import { GalleryModule } from './layout/pages-layout/nomenclatoare/gallery/gallery.module';
import { httpInterceptorProviders } from './interceptors';
import { LogoutComponent } from './logout/logout.component';
import { ClientsModule } from './layout/pages-layout/nomenclatoare/clients/clients.module';
import { UatModule } from './layout/pages-layout/nomenclatoare/uat/uat.module';
import { RapoarteModule } from './layout/pages-layout/nomenclatoare/rapoarte/rapoarte.module';
import { CentralizatorModule } from './layout/pages-layout/nomenclatoare/centralizator/centralizator.module';
import { NotificariModule } from './layout/pages-layout/nomenclatoare/notificari/notificari.module';

import { DatePipe } from '@angular/common';


@NgModule({
    declarations: [AppComponent, LogoutComponent],
    imports: [
        HttpClientModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        LoginModule,
        AuthModuleModule,
        PagesLayoutModule,
        AlertDialogModule,
        LayoutModule,
        SettingsSidenavModule,
        AdministrationModule,
        ClientsModule,
        CetateanModule,
        GalleryModule,
        UatModule,
        RapoarteModule,
        CentralizatorModule,
        NotificariModule
    ],
    providers: [
        httpInterceptorProviders,
        DatePipe // Add DatePipe here
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
