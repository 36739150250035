import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestsService } from '../../requests.service';
import { EditDialogPayload } from '../../request.interface';
import { finalize, Observable } from 'rxjs';
import { helper } from 'src/environments/helper';
import { NotificationService } from 'src/app/services/notification.service';
import { Generic } from 'src/app/interfaces/generic.interface';

@Component({
    selector: 'app-edit-dialog',
    templateUrl: './edit-dialog.component.html',
    styleUrls: ['./edit-dialog.component.scss']
})
export class EditDialogComponent {
    // general error
    errorTitle: string = helper.dialogConfig.headers.errorAtentie;
    errorIcon: string = helper.dialogConfig.icons.error;
    errorType: string = helper.dialogConfig.icons.error;
    helper = helper;

    isLoading = false;

    beneficiari: Observable<Generic[]>;

    form: FormGroup = new FormGroup({
        key: new FormControl(this.data.type, []),
        value: new FormControl(this.data.value, [Validators.required]),
    })

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<EditDialogComponent>,
        private requestService: RequestsService,
        private notificationService: NotificationService,
    ) {
        this.beneficiari = this.data.beneficiari;
    }

    closeDialog() {
        this.dialogRef?.close({
            success: false,
        });
    }

    submitForm() {
        this.isLoading = true;

        const { key, value } = this.form.value as EditDialogPayload;

        const updateValue: EditDialogPayload = {
            key,
            value,
        }

        this.requestService
            .updateInfo(this.data.id_cerere, updateValue)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.dialogRef.close({ success: true })
                },
                error: (err) => {
                    let msg;
                    try {
                        // try to catch the message from server
                        msg = err.error.errors.message[0];
                    } catch (error) {
                        // controll output message
                        msg = helper.dialogConfig.generalMessages.error;
                    }
                    this.notificationService.warningSwal(
                        helper.dialogConfig.headers.errorAtentie,
                        msg,
                        this.errorIcon
                    );
                }
            })
    }
}
