import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { helper } from 'src/environments/helper';
import { RequestsService } from '../../requests.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AddEvaluatorPayload } from '../../request.interface';
import { GenericUser } from 'src/app/interfaces/generic.interface';
import { catchError, distinctUntilChanged, finalize, Observable, of, Subscription, switchMap, tap } from 'rxjs';

@Component({
    selector: 'app-add-evaluator-dialog',
    templateUrl: './add-evaluator-dialog.component.html',
    styleUrls: ['./add-evaluator-dialog.component.scss']
})
export class AddEvaluatorDialogComponent implements OnInit, OnDestroy {
    // general error
    errorTitle: string = helper.dialogConfig.headers.errorAtentie;
    errorIcon: string = helper.dialogConfig.icons.error;
    errorType: string = helper.dialogConfig.icons.error;
    helper = helper;

    isLoading = false;

    form: FormGroup = new FormGroup({
        type: new FormControl(this.data.assignedNr, []),
        operator_id: new FormControl('', [Validators.required]),
        evaluator: new FormControl('', [Validators.required]),
    })

    evaluatori!: GenericUser[];
    filteredEvaluatori!: any[];
    evaluatoriChangesSubscription = new Subscription();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<AddEvaluatorDialogComponent>,
        private requestService: RequestsService,
        private notificationService: NotificationService,
    ) {
        this.evaluatori = this.data.evaluatori;
        console.log(this.evaluatori);
    }

    ngOnInit(): void {
        this.renderEvaluatoriSearch();
    }

    ngOnDestroy(): void {
        if (this.evaluatoriChangesSubscription) {
            this.evaluatoriChangesSubscription.unsubscribe();
        }
    }

    closeDialog() {
        this.dialogRef?.close({
            success: false,
        });
    }

    renderEvaluatoriSearch() {
        this.evaluatoriChangesSubscription = this.form.controls['evaluator'].valueChanges.pipe(
            distinctUntilChanged(),
            switchMap((inputValue) => {
                if (inputValue.length < 1) {
                    this.filteredEvaluatori = [];
                    return of([]);
                }
                return this.filterEvaluatori(inputValue);
            }),
            tap((data: any[]) => {
                this.filteredEvaluatori = data;
            }),
            catchError(() => of([]))
        ).subscribe();
    }

    filterEvaluatori(searchTerm: string): Observable<any[]> {
        const normalizedSearchTerm = this.normalizeString(searchTerm);

        const filtered = this.evaluatori.filter(evaluator =>
            this.normalizeString(evaluator.name).includes(normalizedSearchTerm)
        );

        return of(filtered);
    }

    normalizeString(value: string): string {
        return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
    }

    onEvaluatorChange(evaluator: { id_user: number, name: string }) {
        if (this.evaluatoriChangesSubscription) {
            this.evaluatoriChangesSubscription.unsubscribe();
        }

        this.form.controls['operator_id'].setValue(evaluator.id_user);

        this.renderEvaluatoriSearch();
    }

    submitForm() {
        this.isLoading = true;

        const { operator_id, type } = this.form.value as AddEvaluatorPayload;

        const addValue: AddEvaluatorPayload = {
            operator_id,
            type,
        }

        this.requestService
            .assignEvaluator(this.data.id_cerere, addValue)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.dialogRef.close({ success: true })
                },
                error: (err) => {
                    let msg;
                    try {
                        // try to catch the message from server
                        msg = err.error.errors.message[0];
                    } catch (error) {
                        // controll output message
                        msg = helper.dialogConfig.generalMessages.error;
                    }
                    this.notificationService.warningSwal(
                        helper.dialogConfig.headers.errorAtentie,
                        msg,
                        this.errorIcon
                    );
                }
            })
    }
}
