import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { JudeteService } from '../judete.service';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from 'src/app/services/auth.service';
import { finalize, map, Observable } from 'rxjs';
import { helper } from 'src/environments/helper';
import { Generic } from 'src/app/interfaces/generic.interface';
import { notDefaultValidator } from 'src/app/shared/validators/not-default';

@Component({
    selector: 'app-add-judet',
    templateUrl: './add-judet.component.html',
    styleUrls: ['./add-judet.component.scss'],
})
export class AddJudetComponent implements OnInit {
    // general error
    errorTitle: string = helper.dialogConfig.headers.errorAtentie;
    errorIcon: string = helper.dialogConfig.icons.error;
    errorType: string = helper.dialogConfig.icons.error;
    helper = helper;

    token!: string;
    isLoading = false;
    regions!: Generic[];

    defaultParameter: { name: string; id: string } = {
        name: '-- Alegeți --',
        id: '-1',
    };

    form: FormGroup = new FormGroup({
        name: new FormControl('', Validators.required),
        region: new FormControl('-1', [Validators.required, notDefaultValidator('-1')]),
    });

    constructor(
        private dialogRef: MatDialogRef<AddJudetComponent>,
        private judetService: JudeteService,
        private notificationService: NotificationService,
        private authService: AuthService
    ) {
        this.processToken();
    }

    closeDialog() {
        this.dialogRef?.close({
            success: false,
        });
    }

    ngOnInit(): void {
        this.loadRegions();
    }

    processToken() {
        const tokenObj = this.authService.returnTokenUser();
        tokenObj.then((token) => {
            this.token = token;
            this.judetService.setToken(token);
        });
    }

    loadRegions() {
        this.isLoading = true;
        this.judetService
            .getRegions()
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: (response: any) => {
                    this.regions = [this.defaultParameter, ...response.data];
                },
                error: (error) => {
                    console.log('Error in load regions: ', error);
                }
            })
    }

    submitForm() {
        this.isLoading = true;

        const judet = {
            name: this.form.get('name')?.value,
            region: this.form.get('region')?.value,
        };
        this.judetService
            .addJudet(judet)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: (res) => {
                    this.dialogRef.close({ success: true });
                },
                error: (err) => {
                    let errorMessage =
                        environment.config.customNotifications.generalMessages.error;
                    this.notificationService.warningSwal(
                        this.errorTitle,
                        errorMessage,
                        this.errorIcon
                    );
                },
            });
    }
}
