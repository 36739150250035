import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subject, catchError, map, Observable, throwError } from 'rxjs';
import {
    AddCererePayload,
    EditCererePayload,
    extraActionCererePayload,
    EditRequestStatusPayload,
    EditRequestPriorityPayload,
    EditRequestAssigneePayload,
    UpdateMassStatusPayload,
    UpdateMassAssignPayload,
    IInchidereLucrare,
    UpdateMassPriorityPayload,
    EditDialogPayload,
    AddEvaluatorPayload,
    UploadDocumentPayload,
} from './request.interface';

@Injectable({
    providedIn: 'root',
})
export class RequestsService {
    private token!: string;
    private httpOptions: any;

    private basePath = environment.interop.basePath;
    private getRequestsDTURL =
        this.basePath +
        environment.interop.platform.nomenclatoare.cereri.getCereriListDT;
    private getInfoDocListURL =
        this.basePath +
        environment.interop.platform.nomenclatoare.cereri.getInfoDocList;
    private storeRequestsURL =
        this.basePath +
        environment.interop.platform.nomenclatoare.cereri.storeCereri;
    private updateRequestURL =
        this.basePath +
        environment.interop.platform.nomenclatoare.cereri.updateCereri;
    private deleteRequestURL =
        this.basePath +
        environment.interop.platform.nomenclatoare.cereri.deleteCereri;

    private rePostMessageURL =
        this.basePath + environment.interop.platform.nomenclatoare.cereri.rePostMessage;

    // Vizualizare solicitare

    private validateCerereUrl =
        this.basePath +
        environment.interop.platform.nomenclatoare.cereri.validateCerere;
    private invalidateCerereUrl =
        this.basePath +
        environment.interop.platform.nomenclatoare.cereri.invalidateCerere;
    private anulareCerereUrl =
        this.basePath +
        environment.interop.platform.nomenclatoare.cereri.anulareCerere;
    private finalizareCerereUrl =
        this.basePath +
        environment.interop.platform.nomenclatoare.cereri.finalizareCerere;
    private resetValidareCerereUrl =
        this.basePath +
        environment.interop.platform.nomenclatoare.cereri.resetValidareCerere;
    private resetInvalidareCerereUrl =
        this.basePath +
        environment.interop.platform.nomenclatoare.cereri.resetInvalidareCerere;
    private resetClasareCerereUrl =
        this.basePath +
        environment.interop.platform.nomenclatoare.cereri.resetClasareCerere;
    private resetInchidereCerereUrl =
        this.basePath +
        environment.interop.platform.nomenclatoare.cereri.resetInchidereCerere;
    private preluareCerereUrl =
        this.basePath +
        environment.interop.platform.nomenclatoare.cereri.preluareCerere;
    private postMessageUrl =
        this.basePath +
        environment.interop.platform.nomenclatoare.cereri.postMessage;
    private updatePriorityUrl =
        this.basePath +
        environment.interop.platform.nomenclatoare.cereri.updatePriority;
    private updateStatusUrl =
        this.basePath +
        environment.interop.platform.nomenclatoare.cereri.updateStatus;
    private updateAssigneeURL =
        this.basePath +
        environment.interop.platform.nomenclatoare.cereri.updateAssignee;
    public downloadDocumentUrl =
        this.basePath +
        environment.interop.platform.nomenclatoare.cereri.getDocument;
    private fileUploadUrl =
        this.basePath +
        environment.interop.platform.nomenclatoare.cereri.uploadFileRegister;

    private getHistoryByIdUrl =
        this.basePath +
        environment.interop.platform.nomenclatoare.cereriHistory
            .getCerereHistoryByCerereId;

    private exportHistoryUrl = this.basePath + environment.interop.platform.nomenclatoare.cereri.exportHistory;
    private updateInfoUrl = this.basePath + environment.interop.platform.nomenclatoare.cereri.updateInfo;
    private resendNotificationUrl = this.basePath + environment.interop.platform.nomenclatoare.cereri.resendNotification;

    // Compartimente

    private getCompartimenteByDirectieUrl =
        this.basePath +
        environment.interop.platform.nomenclatoare.compartiment
            .getCompartimenteByDirectie;

    private getCompartimenteActiveUrl =
        this.basePath +
        environment.interop.platform.nomenclatoare.compartiment
            .getCompartimenteActive;

    // Directii

    private getDirectiiActiveUrl =
        this.basePath +
        environment.interop.platform.nomenclatoare.directie.getDirectiiActive;
    // Mass update & assign

    private massUpdateStatusURL =
        this.basePath +
        environment.interop.platform.nomenclatoare.cereri.massUpdateStatus;

    private massUpdatePriorityURL =
        this.basePath +
        environment.interop.platform.nomenclatoare.cereri.massUpdatePriority;

    private massAssignURL =
        this.basePath +
        environment.interop.platform.nomenclatoare.cereri.massAssign;

    public exportCSVUrl = this.basePath + environment.interop.platform.nomenclatoare.cereri.exportCSV;

    private getEmailListUrl = this.basePath + environment.interop.platform.nomenclatoare.cereri.emailList;
    private getOTPListUrl = this.basePath + environment.interop.platform.nomenclatoare.cereri.otpList;

    private validateDocumentUrl = this.basePath + environment.interop.platform.nomenclatoare.cereri.validateDocument;
    private resetValidateDocumentUrl = this.basePath + environment.interop.platform.nomenclatoare.cereri.resetValidateDocument;
    private invalidateDocumentUrl = this.basePath + environment.interop.platform.nomenclatoare.cereri.invalidateDocument;
    private resetInvalidateDocumentUrl = this.basePath + environment.interop.platform.nomenclatoare.cereri.resetInvalidateDocument;
    private cancelDocumentUrl = this.basePath + environment.interop.platform.nomenclatoare.cereri.cancelDocument;
    private resetCancelDocumentUrl = this.basePath + environment.interop.platform.nomenclatoare.cereri.resetCancelDocument;

    private getEvaluatoriUrl = this.basePath + environment.interop.platform.nomenclatoare.cereri.getEvaluatori;
    private assignEvaluatorUrl = this.basePath + environment.interop.platform.nomenclatoare.cereri.assignEvaluator;
    private endEvaluationUrl = this.basePath + environment.interop.platform.nomenclatoare.cereri.endEvaluation;
    private resetEndEvaluationUrl = this.basePath + environment.interop.platform.nomenclatoare.cereri.resetEndEvaluation;
    private uploadDocumentUrl = this.basePath + environment.interop.platform.nomenclatoare.cereri.uploadDocument;

    private sablonContent = new Subject<string>();
    sablonContent$ = this.sablonContent.asObservable();

    constructor(private http: HttpClient) { }

    getRequestsDT() {
        return this.http.get(this.getRequestsDTURL, this.httpOptions);
    }

    addRequest(request: AddCererePayload) {
        return this.http.post(this.storeRequestsURL, request, this.httpOptions);
    }

    updateRequest(request: EditCererePayload) {
        return this.http.put(this.updateRequestURL, request, this.httpOptions);
    }

    deleteRequest(id: number) {
        return this.http.delete(this.deleteRequestURL + id);
    }

    getInfoDocList(id: number) {
        return this.http.get(this.getInfoDocListURL + id);
    }

    preluareCerere(cerereId: number) {
        return this.http.post(this.preluareCerereUrl + cerereId, this.httpOptions);
    }

    validateCerere(id: number) {
        return this.http.get(this.validateCerereUrl + id);
    }

    invalidateCerere(data: extraActionCererePayload) {
        return this.http.post(this.invalidateCerereUrl, data, this.httpOptions);
    }

    anulareCerere(data: extraActionCererePayload) {
        return this.http.post(this.anulareCerereUrl, data, this.httpOptions);
    }

    resetValidareCerere(cerereId: number, data: any) {
        return this.http.get(this.resetValidareCerereUrl + cerereId, data);
    }

    resetInvalidareCerere(cerereId: number, data: any) {
        return this.http.get(this.resetInvalidareCerereUrl + cerereId, data);
    }

    resetClasareCerere(cerereId: number, data: any) {
        return this.http.get(this.resetClasareCerereUrl + cerereId, data);
    }

    resetInchidereCerere(cerereId: number, data: any) {
        return this.http.get(this.resetInchidereCerereUrl + cerereId, data);
    }

    finalizareCerere(cerereId: number, data: IInchidereLucrare) {
        return this.http.post(this.finalizareCerereUrl + cerereId, data, this.httpOptions);
    }

    postMessage(id: number, message: any) {
        return this.http.post(
            this.postMessageUrl,
            { ...message, id_cerere: id },
            this.httpOptions
        );
    }

    updateRequestStatus(status: EditRequestStatusPayload) {
        return this.http.post(this.updateStatusUrl, status, this.httpOptions);
    }

    updateRequestPriority(priority: EditRequestPriorityPayload) {
        return this.http.post(this.updatePriorityUrl, priority, this.httpOptions);
    }

    updateRequestAssignee(data: EditRequestAssigneePayload) {
        return this.http.post(this.updateAssigneeURL, data, this.httpOptions);
    }

    getHistoryById(id: number) {
        return this.http.get(this.getHistoryByIdUrl + id, this.httpOptions);
    }

    fileUpload(formData: FormData) {
        return this.http.post<{ data: any }>(this.fileUploadUrl, formData);
    }

    getCompartimenteByDirectieId(id: number) {
        return this.http
            .get<{ errors: boolean; data: any[]; status_code: number }>(
                this.getCompartimenteByDirectieUrl + id
            )
            .pipe(
                map((response) =>
                    response.data.map((item) => ({
                        id: item.id_compartiment,
                        name: item.name,
                    }))
                ),
                catchError((error) => {
                    console.error('Error in getCompartimenteByDirectieId:', error);
                    return throwError(error);
                })
            );
    }

    getCompartimenteActive() {
        return this.http
            .get<{ errors: boolean; data: any[]; status_code: number }>(
                this.getCompartimenteActiveUrl
            )
            .pipe(
                map((response) =>
                    response.data.map((item) => ({
                        id: item.id_compartiment,
                        name: item.name,
                    }))
                ),
                catchError((error) => {
                    console.error('Error in get compartimente active:', error);
                    return throwError(error);
                })
            );
    }

    getDirectii() {
        return this.http
            .get<{ errors: boolean; data: any[]; status_code: number }>(
                this.getDirectiiActiveUrl
            )
            .pipe(
                map((response) =>
                    response.data.map((item) => ({
                        id: item.id_directie,
                        name: item.name,
                    }))
                ),
                catchError((error) => {
                    console.error('Error in get directii active:', error);
                    return throwError(error);
                })
            );
    }

    setToken(token: string) {
        this.token = token;
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.token}`,
            }),
        };
    }

    updateMassStatus(data: UpdateMassStatusPayload) {
        return this.http.post(this.massUpdateStatusURL, data, this.httpOptions);
    }

    updateMassPriority(data: UpdateMassPriorityPayload) {
        return this.http.post(this.massUpdatePriorityURL, data, this.httpOptions);
    }

    updateMassAssign(data: UpdateMassAssignPayload) {
        return this.http.post(this.massAssignURL, data, this.httpOptions);
    }

    sendContent(content: string) {
        this.sablonContent.next(content);
    }

    rePostMessage(id: number) {
        return this.http.post(this.rePostMessageURL + id, this.httpOptions);
    }

    exportHistory(cerere_id: number, user_type: number): Observable<Blob> {
        return this.http.get(this.exportHistoryUrl + cerere_id + '/' + user_type, {
            responseType: 'blob',
        });
    }

    updateInfo(id: number, data: EditDialogPayload) {
        return this.http.post(this.updateInfoUrl + id, data);
    }

    resendNotification(id: any, tip_notificare: number) {
        return this.http.get(this.resendNotificationUrl + id + '/' + tip_notificare);
    }

    validateDocument(id: number) {
        return this.http.get(this.validateDocumentUrl + id);
    }

    resetValidateDocument(id: number, data: string) {
        return this.http.post(this.resetValidateDocumentUrl + id, { message: data });
    }

    invalidateDocument(id: number, data: string) {
        return this.http.post(this.invalidateDocumentUrl, { document_id: id, observations: data });
    }

    resetInvalidateDocument(id: number, data: string) {
        return this.http.post(this.resetInvalidateDocumentUrl + id, { message: data });
    }

    cancelDocument(id: number, data: string) {
        return this.http.post(this.cancelDocumentUrl, { document_id: id, observations: data });
    }

    resetCancelDocument(id: number, data: string) {
        return this.http.post(this.resetCancelDocumentUrl, { message: data });
    }

    getEmailList(id: any) {
        return this.http.get(this.getEmailListUrl + id);
    }

    getOTPList(id: any) {
        return this.http.get(this.getOTPListUrl + id);
    }

    getEvaluatori() {
        return this.http.get(this.getEvaluatoriUrl);
    }

    assignEvaluator(id: number, data: AddEvaluatorPayload) {
        return this.http.post(this.assignEvaluatorUrl + id, data)
    }

    endEvaluation(id: number) {
        return this.http.get(this.endEvaluationUrl + id);
    }

    resetEndEvaluation(id: number, type: number, data: string) {
        return this.http.post(this.resetEndEvaluationUrl + id, { type: type, message: data });
    }

    uploadDocument(id: number, data: UploadDocumentPayload) {
        return this.http.post(this.uploadDocumentUrl + id, data)
    }
}
