<div class="edit-container">
	<h1 class="edit-title">
        <span *ngIf="data.type === 1">Adăugare </span>
        <span *ngIf="data.type === 2">Editare </span>  
        Evaluator {{ data.assignedNr == 1 ? 'Administrativ' : 'Tehnic' }}
    </h1>

	<form class="edit-card__form" [formGroup]="form" (submit)="submitForm()">
		<div class="row">
			<div class="col">
                <mat-form-field class="w-100">
                    <mat-label>Evaluator</mat-label>
                    <input type="text" matInput formControlName="evaluator" [matAutocomplete]="auto" />
                    <mat-icon matSuffix class="opacity-50">search</mat-icon>
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let evaluator of filteredEvaluatori" [value]="evaluator.name"
                            (onSelectionChange)="onEvaluatorChange(evaluator)">
                            {{ evaluator.name }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
			</div>
		</div>

        <div class="row">
			<div class="col d-flex justify-content-between">
				<button class="buttons" type="button" mat-flat-button (click)="closeDialog()">
					{{ helper.dialogConfig.buttonLabels.close }}
				</button>
				<button class="buttons" type="submit" mat-flat-button color="primary" [disabled]="form.invalid">
					{{ helper.dialogConfig.buttonLabels.save }}
				</button>
			</div>
		</div>
	</form>
	<div class="section-spinner position-absolute" *ngIf="isLoading">
		<div class="spinner position-absolute top-50 start-50 translate-middle">
			<mat-spinner></mat-spinner>
			<h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
		</div>
	</div>
</div>