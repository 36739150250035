export const environment = {
    version: '1.0.0',
    production: true,
    env: 'prod',
    config: {
        appName: 'Ministerul Cercetării, Inovării și Digitalizării',
        appDescription: 'Portal Operator',
        fromTokenValid: 'https://digilocal-core.mcid.gov.ro/api/login',
        tokenKey: 'wpl-mcid-operator-app-token', // this token is used for every request where is needed
        userKey: 'wpl-mcid-operator-app-user', // here we find local storage with minimal user data
        storageKey: 'wpl-mcid-operator', // here we find key for predefined local storage for app functionalities
        copyrightText:
            'Copyright © Ministerul Cercetării, Inovării și Digitalizării <br/>2024',
        defaultLNG: 'ro',
        emailRegex:
            '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$',
        codeSMSRegex: /^[0-9]+$/,
        phoneRegex: '[0-9]{6,20}',
        cnpRegex: '[0-9]{13,13}',
        validatorsAccrossApp: {
            minPassword: 8,
            maxPassword: 30,
            emailMaxLength: 50,
            minStringLength: 2,
            maxStringLength: 60,
            minSmsCodeLength: 1,
            maxSmsCodeLength: 1,
        },
        customNotifications: {
            icons: {
                success: 'success',
                error: 'error',
                info: 'info',
                warning: 'warning',
            },
            headers: {
                success: 'Succes',
                error: 'Atenție',
                successForm: 'Felicitări!',
            },
            generalMessages: {
                error:
                    'Ne pare rău, dar am întâmpinat o problemă. Vă rugăm să reîncercați. Dacă problema persistă, vă rugăm să ne contactați prin butonul de Suport Tehnic.',
                fileErrorMsg:
                    'Ne pare rău, dar fișierul selectat nu a putut fi încărcat. Vă rugăm să reîncercați!',
            },
        },
        romanianLanguageDataTable: {
            sEmptyTable: 'Niciun rezultat găsit',
            sInfo: 'Afișate de la _START_ până la _END_ din _TOTAL_ înregistrări',
            sInfoEmpty: 'Afișate de la 0 până la 0 din 0 înregistrări',
            sInfoFiltered: '(filtrat din _TOTAL_ înregistrări)',
            sInfoPostFix: '',
            sInfoThousands: ',',
            sLengthMenu: 'Afișează _MENU_ înregistrări pe pagină',
            sLoadingRecords: 'Încărcare...',
            sProcessing: 'Procesare...',
            sSearch: 'Caută:',
            sZeroRecords: 'Niciun rezultat găsit',
            oPaginate: {
                sFirst: 'Prima',
                sLast: 'Ultima',
                sNext: 'Următoarea',
                sPrevious: 'Anterioară',
            },
            oAria: {
                sSortAscending: ': activează pentru a sorta coloana crescător',
                sSortDescending: ': activează pentru a sorta coloana descrescător',
            },
        },
    },
    interop: {
        basePath: 'https://digilocal-core.mcid.gov.ro/',
        linkRedirectAuth: 'https://digilocal-app.mcid.gov.ro/',
        clientId: 1,
        moduleId: 1,
        notifications: {
            authNotifications: 'api/nomenclatoare/user/auth-notifications',
        },
        user: {
            // PLATFORM USER MECHANISM
            loginUrl: 'api/login',
            logoutUrl: 'api/logout',
            getUserDetails: 'api/user',
            changeUserPassforce: 'api/user/update-password',
            generateShortToken: 'api/generateShortToken/',
        },
        gallery: {
            uploadImage: 'api/gallery/upload-image',
            listGallery: 'api/gallery/list-gallery',
            deleteImage: 'api/gallery/delete-image/',
        },
        platform: {
            app: {
                settings: {
                    // PLATFORM CONFIGURATIONS
                    configurations: {
                        getConfigurations: 'api/settings/configuration/list',
                        getSlugConfiguration: 'api/settings/configuration/find/', // + slug
                        getConfiguration: 'api/settings/configuration/one/', // + id
                        putConfiguration: 'api/settings/configuration/update', // id in payload
                        storeConfiguration: 'api/settings/configuration/store',
                        deleteConfiguration: 'api/settings/configuration/delete/', // id in payload
                        getConfigurationsDT: 'api/settings/configuration/list-dt',
                    },
                },
            },
            nomenclatoare: {
                // nomenclatoare for the platform
                passwords: {
                    changePassword: 'api/nomenclatoare/user/change-password',
                },
                user: {
                    storeUser: 'api/register',
                    getUsers: 'api/nomenclatoare/user/list',
                    getUserTypeActive: 'api/settings/user-type/list-active',
                    getUsersDT: 'api/nomenclatoare/user/list-dt',
                    getUsersActive: 'api/nomenclatoare/user/list/active',
                    getUsersActivePublic: 'api/nomenclatoare/user/list/public',
                    getUsersPublic: 'api/nomenclatoare/user/list/public',
                    getUser: 'api/nomenclatoare/user/find/', // + id
                    updateUser: 'api/nomenclatoare/user/update',
                    changeStatusUserActive: 'api/nomenclatoare/user/active/', // + id
                    changeStatusUserPublic: 'api/nomenclatoare/user/public/', // + id
                    resetPasswordAdmin: 'api/nomenclatoare/user/reset-password/', // + id
                    getActiveUsersByOrganigrama:
                        'api/nomenclatoare/user/list-users-organigrama',
                },
                cereri: {
                    getCereriList: 'api/nomenclatoare/cerere/list',
                    getCereriListDT: 'api/cerere/list-dt',
                    storeCereri: 'api/nomenclatoare/cerere/store',
                    updateCereri: 'api/nomenclatoare/cerere/update',
                    deleteCereri: 'api/nomenclatoare/cerere/delete/', // + id
                    getInfoDocList:
                        'api/cerere/find/', // + id
                    validateCerere: 'api/cerere/validate/', // + id
                    invalidateCerere: 'api/cerere/invalidate', // id in payload
                    anulareCerere: 'api/cerere/cancel', // id in payload
                    finalizareCerere: 'api/cerere/finalize/', // + id
                    resetValidareCerere: 'api/cerere/rollback-validation/', // + id
                    resetInvalidareCerere: 'api/cerere/rollback-invalidation/', // + id
                    resetClasareCerere: 'api/cerere/rollback-cancel/', // + id
                    resetInchidereCerere: 'api/cerere/rollback-finalize/', // + id
                    postMessage: 'api/cerere/post-message', // id in payload
                    rePostMessage: 'api/cerere/re-post-message/', // + id
                    updatePriority: 'api/cerere/update-priority', // id in payload
                    updateStatus: 'api/cerere/update-status', // id in payload
                    updateAssignee: 'api/cerere/update-assignee', // id in payload
                    preluareCerere: 'api/cerere/preluare-cerere/', // + id
                    massAssign: 'api/cerere/mass-assign', // id in payload
                    massUpdateStatus: 'api/cerere/mass-update-status',
                    massUpdatePriority: 'api/cerere/mass-update-priority',
                    uploadFileRegister: 'api/upload-file-register',
                    uploadFileRegisterBase64: 'api/upload-file-register-base64',
                    getDocument: 'api/cerere/download/document/', // + id,
                    numarRegistratura: 'api/nomenclatoare/cerere/generateRegNumber/',
                    exportHistory: 'api/nomenclatoare/cerere/download-history/', //+ id_cerere + user type (2=> ceteatan, 1=> operator)
                    fetchReports: 'api/cerere/fetch-reports',
                    updateInfo: 'api/cerere/update-info/', // + id_cerere
                    resendNotification: 'api/cerere/resend-notification/', // + id_cerere/{{tip_notificare}}
                    exportCSV: 'api/cerere/list-csv',
                    validateDocument: 'api/document/validate/',
                    resetValidateDocument: 'api/document/rollback-validation/',
                    invalidateDocument: 'api/document/invalidate',
                    resetInvalidateDocument: 'api/document/rollback-invalidation/',
                    cancelDocument: 'api/document/cancel',
                    resetCancelDocument: 'api/document/rollback-cancel/',
                    emailList: 'api/cerere/email-list/', // + id_cerere
                    otpList: 'api/cerere/otp-list/', // + id_cerere
                    getEvaluatori: 'api/cerere/evaluators',
                    assignEvaluator: 'api/cerere/assign-operator/', // + id_cerere
                    endEvaluation: 'api/cerere/end-evaluation/', // + id_cerere
                    resetEndEvaluation: 'api/cerere/rollback-end-evaluation/', // + id_cerere/evaluator_id
                    uploadDocument: 'api/document/upload/', // + id_cerere
                },
                cereriHistory: {
                    getCereriHistoryList: 'api/nomenclatoare/cerere-history/list',
                    getCereriHistoryListDT: 'api/nomenclatoare/cerere-history/list-dt',
                    storeCerereHistory: 'api/nomenclatoare/cerere-history/store',
                    updateCerereHistory: 'api/nomenclatoare/cerere-history/update',
                    deleteCerereHistory: 'api/nomenclatoare/cerere-history/delete/', // + id
                    getCerereHistoryByCerereId: 'api/cerere/get-history/', // + id
                },
                userType: {
                    getUserTypes: 'api/settings/user-type/list',
                    getUserTypesDT: 'api/settings/user-type/list-dt',
                    storeUserTypes: 'api/settings/user-type/store',
                    getUserTypeActive: 'api/settings/user-type/list-active',
                    getUserType: 'api/settings/user-type/find/', // + id
                    updateUserType: 'api/settings/user-type/update',
                    changeStatusUserTypeActive: 'api/settings/user-type/setActive/', // + id
                },
                functie: {
                    // functii operatori from the platform
                    getFunctii: 'api/nomenclatoare/functie/list',
                    getFunctiiDT: 'api/nomenclatoare/functie/list-dt',
                    getFunctiiActive: 'api/nomenclatoare/functie/list/active',
                    getFunctiiActivePublic: 'api/nomenclatoare/functie/list/public',
                    getFunctie: 'api/nomenclatoare/functie/find/', // + id
                    putFunctie: 'api/nomenclatoare/functie/update', // id in payload
                    changeStatusFunctieActive: 'api/nomenclatoare/functie/active/', // + id
                    changeStatusFunctiePublic: 'api/nomenclatoare/functie/public/', // + id
                    deleteFunctie: 'api/nomenclatoare/functie/delete/', // + id
                    storeFunctie: 'api/nomenclatoare/functie/store',
                },
                cerereSabloane: {
                    getCerereSabloaneDT: 'api/nomenclatoare/cerere-sabloane/list-dt',
                    getCerereSabloane: 'api/nomenclatoare/cerere-sabloane/list',
                    getCerereSablon: 'api/nomenclatoare/cerere-sabloane/find/', // + id
                    putCerereSablon: 'api/nomenclatoare/cerere-sabloane/update', // id in payload
                    deleteCerereSablon: 'api/nomenclatoare/cerere-sabloane/delete/', // + id
                    storeCerereSablon: 'api/nomenclatoare/cerere-sabloane/store',
                    getCerereSabloaneActive:
                        'api/nomenclatoare/cerere-sabloane/list/active',
                    getCerereSabloaneActivePublic:
                        'api/nomenclatoare/cerere-sabloane/list/public',
                    changeStatusCerereSablonActive:
                        'api/nomenclatoare/cerere-sabloane/active/', // + id
                    changeStatusCerereSablonPublic:
                        'api/nomenclatoare/cerere-sabloane/public/', // + id
                },
                client: {
                    // clients from the platform, not citizens.. which do we map our modules within clients
                    getClients: 'api/client/list',
                    getClientsDT: 'api/client/list-dt',
                    getClientsActive: 'api/client/list/active',
                    getClientsActivePublic: 'api/client/list/public',
                    getClient: 'api/client/find/', // + id
                    putClient: 'api/client/update', // id in payload
                    changeStatusClientActive: 'api/client/active/', // + id
                    changeStatusClientPublic: 'api/client/public/', // + id
                    deleteClient: 'api/client/delete/', // + id
                    storeClient: 'api/client/store',
                },
                directie: {
                    // directii from the platform
                    getDirectii: 'api/nomenclatoare/directie/list',
                    getDirectiiDT: 'api/nomenclatoare/directie/list-dt',
                    getDirectiiActive: 'api/nomenclatoare/directie/list/active',
                    getDirectiiActivePublic: 'api/nomenclatoare/directie/list/public',
                    getDirectie: 'api/nomenclatoare/directie/find/', // + id
                    putDirectie: 'api/nomenclatoare/directie/update', // id in payload
                    changeStatusDirectieActive: 'api/nomenclatoare/directie/active/', // + id
                    changeStatusDirectiePublic: 'api/nomenclatoare/directie/public/', // + id
                    deleteDirectie: 'api/nomenclatoare/directie/delete/', // + id
                    storeDirectie: 'api/nomenclatoare/directie/store',
                },
                compartiment: {
                    // compartimente(from directii) from the platform
                    getCompartimente: 'api/nomenclatoare/directie-compartiment/list',
                    getCompartimenteByDirectie:
                        'api/nomenclatoare/directie-compartiment/listByDirectie/',
                    getCompartimenteDT: 'api/nomenclatoare/directie-compartiment/list-dt',
                    getCompartimenteActive:
                        'api/nomenclatoare/directie-compartiment/list/active',
                    getCompartimenteActivePublic:
                        'api/nomenclatoare/directie-compartiment/list/public',
                    getCompartiment: 'api/nomenclatoare/directie-compartiment/find/', // + id
                    putCompartiment: 'api/nomenclatoare/directie-compartiment/update', // id in payload
                    changeStatusCompartimentActive:
                        'api/nomenclatoare/directie-compartiment/active/', // + id
                    changeStatusCompartimentPublic:
                        'api/nomenclatoare/directie-compartiment/public/', // + id
                    deleteCompartiment: 'api/nomenclatoare/directie-compartiment/delete/', // + id
                    storeCompartiment: 'api/nomenclatoare/directie-compartiment/store',
                },
                imageType: {
                    // used to resize images to certain sizes
                    getImageTypesDT: 'api/settings/image-type/list-dt',
                    getImageTypes: 'api/settings/image-type/list',
                    getImageType: 'api/settings/image-type/find/', // + id
                    storeImageType: 'api/settings/image-type/store',
                    putImageType: 'api/settings/image-type/update', // id in payload
                    deleteImageType: 'api/settings/image-type/delete/', // + id
                },
                cerereType: {
                    getCerereTypesDT: 'api/nomenclatoare/cerere-type/list-dt',
                    getCerereTypes: 'api/nomenclatoare/cerere-type/list',
                    getCerereType: 'api/nomenclatoare/cerere-type/find/', // + id
                    putCerereType: 'api/nomenclatoare/cerere-type/update', // id in payload
                    deleteCerereType: 'api/nomenclatoare/cerere-type/delete/', // + id
                    storeCerereType: 'api/nomenclatoare/cerere-type/store',
                    getCerereTypesActive: 'api/nomenclatoare/cerere-type/list/active',
                    getCerereTypesActivePublic:
                        'api/nomenclatoare/cerere-type/list/public',
                    changeStatusCerereTypeActive: 'api/nomenclatoare/cerere-type/active/', // + id
                    changeStatusCerereTypePublic: 'api/nomenclatoare/cerere-type/public/', // + id
                },
                cerereStatus: {
                    getCerereStatusesDT: 'api/nomenclatoare/cerere-status/list-dt',
                    getCerereStatuses: 'api/nomenclatoare/cerere-status/list',
                    getCerereStatus: 'api/nomenclatoare/cerere-status/find/', // + id
                    putCerereStatus: 'api/nomenclatoare/cerere-status/update', // id in payload
                    deleteCerereStatus: 'api/nomenclatoare/cerere-status/delete/', // + id
                    storeCerereStatus: 'api/nomenclatoare/cerere-status/store',
                    getCerereStatusesActive:
                        'api/nomenclatoare/cerere-status/list/active',
                    getCerereStatusesActivePublic:
                        'api/nomenclatoare/cerere-status/list/public',
                    changeStatusCerereStatusActive:
                        'api/nomenclatoare/cerere-status/active/', // + id
                    changeStatusCerereStatusPublic:
                        'api/nomenclatoare/cerere-status/public/', // + id
                    updateOrder: 'api/nomenclatoare/cerere-status/update-order/', // + id => put request
                },
                cererePriority: {
                    getCererePrioritiesDT: 'api/nomenclatoare/cerere-priority/list-dt',
                    getCererePriorities: 'api/nomenclatoare/cerere-priority/list',
                    getCererePriority: 'api/nomenclatoare/cerere-priority/find/', // + id
                    putCererePriority: 'api/nomenclatoare/cerere-priority/update', // id in payload
                    deleteCererePriority: 'api/nomenclatoare/cerere-priority/delete/', // + id
                    storeCererePriority: 'api/nomenclatoare/cerere-priority/store',
                    getCererePrioritiesActive:
                        'api/nomenclatoare/cerere-priority/list/active',
                    getCererePrioritiesActivePublic:
                        'api/nomenclatoare/cerere-priority/list/public',
                    changeStatusCererePriorityActive:
                        'api/nomenclatoare/cerere-priority/active/', // + id
                    changeStatusCererePriorityPublic:
                        'api/nomenclatoare/cerere-priority/public/', // + id
                },
                cerereSeries: {
                    getCerereSeriesDT: 'api/nomenclatoare/cerere-series/list-dt',
                    getCerereSeries: 'api/nomenclatoare/cerere-series/list',
                    getCerereSerie: 'api/nomenclatoare/cerere-series/find/', // + id
                    putCerereSerie: 'api/nomenclatoare/cerere-series/update', // id in payload
                    deleteCerereSerie: 'api/nomenclatoare/cerere-series/delete/', // + id
                    storeCerereSerie: 'api/nomenclatoare/cerere-series/store',
                    getCerereSeriesActive:
                        'api/nomenclatoare/cerere-series/list/active',
                    getCerereSeriesActivePublic:
                        'api/nomenclatoare/cerere-series/list/public',
                    changeStatusCerereSerieActive:
                        'api/nomenclatoare/cerere-series/active/', // + id
                    changeStatusCerereSeriePublic:
                        'api/nomenclatoare/cerere-series/public/', // + id
                    updateCurrentNumber: 'api/nomenclatoare/cerere-series/update-current-number/', // + id
                },
                platformSection: {
                    // sections to be displayed and grouped for cetateni. !!! ALSO. From this section, we can get all the next resources to show to operator. Content, FAQ, Announces
                    getPlatformSectionsDT: 'api/nomenclatoare/platform-section/list-dt',
                    getPlatformSections: 'api/nomenclatoare/platform-section/list',
                    getPlatformSection: 'api/nomenclatoare/platform-section/find/', // + id
                    putPlatformSection: 'api/nomenclatoare/platform-section/update', // id in payload
                    deletePlatformSection: 'api/nomenclatoare/platform-section/delete/', // + id
                    storePlatformSection: 'api/nomenclatoare/platform-section/store',
                    getPlatformSectionsActive: 'api/nomenclatoare/platform-section/list/active',
                    changeStatusPlatformSectionActive: 'api/nomenclatoare/platform-section/active/', // + id
                },
                platformFAQ: {
                    // frequently asked questions, for platform cetateni
                    getPlatformFAQsDT: 'api/nomenclatoare/platform-faq/list-dt',
                    getPlatformFAQs: 'api/nomenclatoare/platform-faq/list', // ?section_id={id}
                    getPlatformFAQ: 'api/nomenclatoare/platform-faq/find/', // + id
                    putPlatformFAQ: 'api/nomenclatoare/platform-faq/update', // id in payload
                    deletePlatformFAQ: 'api/nomenclatoare/platform-faq/delete/', // + id
                    storePlatformFAQ: 'api/nomenclatoare/platform-faq/store',
                    getPlatformFAQsActive: 'api/nomenclatoare/platform-faq/list/active', // ?section_id={id}
                    changeStatusPlatformFAQActive: 'api/nomenclatoare/platform-faq/active/', // + id
                    updateOrder: 'api/nomenclatoare/platform-faq/update-order/', // + id => put request
                },
                platformContent: {
                    // content for platform cetateni
                    getPlatformContentsDT: 'api/nomenclatoare/platform-content/list-dt',
                    getPlatformContents: 'api/nomenclatoare/platform-content/list', // ?section_id={id}
                    getPlatformContent: 'api/nomenclatoare/platform-content/find/', // + id
                    putPlatformContent: 'api/nomenclatoare/platform-content/update', // id in payload
                    deletePlatformContent: 'api/nomenclatoare/platform-content/delete/', // + id
                    storePlatformContent: 'api/nomenclatoare/platform-content/store',
                    getPlatformContentsActive: 'api/nomenclatoare/platform-content/list/active', // ?section_id={id}
                    changeStatusPlatformContentActive: 'api/nomenclatoare/platform-content/active/', // + id
                    updateOrder: 'api/nomenclatoare/platform-content/update-order/', // + id => put request
                },
                platformAnnounces: {
                    // announces for platform cetateni
                    getPlatformAnnouncesDT: 'api/nomenclatoare/platform-announces/list-dt',
                    getPlatformAnnounces: 'api/nomenclatoare/platform-announces/list', // ?section_id={id}
                    getPlatformAnnounce: 'api/nomenclatoare/platform-announces/find/', // + id
                    putPlatformAnnounce: 'api/nomenclatoare/platform-announces/update', // id in payload
                    deletePlatformAnnounce: 'api/nomenclatoare/platform-announces/delete/', // + id
                    storePlatformAnnounce: 'api/nomenclatoare/platform-announces/store',
                    getPlatformAnnouncesActive: 'api/nomenclatoare/platform-announces/list/active', // ?section_id={id}
                    changeStatusPlatformAnnounceActive: 'api/nomenclatoare/platform-announces/active/', // + id
                },
                judete: {
                    getJudete: 'api/nomenclatoare/judet/list',
                    getJudeteDT: 'api/nomenclatoare/judet/list-dt',
                    getJudeteActive: 'api/nomenclatoare/judet/list/active',
                    getJudetePublic: 'api/nomenclatoare/judet/list/public',
                    updateJudete: 'api/nomenclatoare/judet/update', // id in payload
                    changeStatusJudeteActive: 'api/nomenclatoare/judet/active/', // + id
                    changeStatusJudetePublic: 'api/nomenclatoare/judet/public/', // + id
                    deleteJudet: 'api/nomenclatoare/judet/delete/', // + id
                    storejudet: 'api/nomenclatoare/judet/store',
                    getRegions: 'api/nomenclatoare/judet/regions',
                },
                crons: {
                    cronList: 'api/settings/cron/list',
                    cronListDT: 'api/settings/cron/list-dt',
                    cronActive: 'api/settings/cron/list/active',
                    cronStore: 'api/settings/cron/store',
                    cronUpdate: 'api/settings/cron/update',
                    cronActivePut: 'api/settings/cron/active/',
                    cronDelete: 'api/settings/cron/delete/',
                },
                tipuriDocumente: {
                    getTipuriDocumenteDT: 'api/nomenclatoare/cerere-document-type/list-dt',
                    getTipuriDocumente: 'api/nomenclatoare/cerere-document-type/list',
                    getTipDocumente: 'api/nomenclatoare/cerere-document-type/find/', // + id
                    putTipDocumente: 'api/nomenclatoare/cerere-document-type/update', // id in payload
                    deleteTipDocumente: 'api/nomenclatoare/cerere-document-type/delete/', // + id
                    storeTipDocumente: 'api/nomenclatoare/cerere-document-type/store',
                    getTipuriDocumenteActive:
                        'api/nomenclatoare/cerere-document-type/list/active',
                    changeStatusTipDocumenteActive:
                        'api/nomenclatoare/cerere-document-type/active/', // + id
                },
                uat: {
                    getUatsDt: 'api/nomenclatoare/uat/list-dt',
                    getUats: 'api/nomenclatoare/uat/list',
                    getUat: 'api/nomenclatoare/uat/find/', // + id
                    putUat: 'api/nomenclatoare/uat/update',
                    deleteUat: 'api/nomenclatoare/uat/delete/', // + id
                    storeUat: 'api/nomenclatoare/uat/store',
                    getUatsActive: 'api/nomenclatoare/uat/list/active',
                    changeStatusUatActive: 'api/nomenclatoare/uat/active/', // + id
                    changeStatusUatPublic: 'api/nomenclatoare/uat/public/', // + id
                    searchUat: 'api/nomenclatoare/uat/search',
                    exportCSV: 'api/nomenclatoare/uat/export-csv',
                },
                centralizator: {
                    getCentralizatorDt: 'api/cerere/centralizator',
                    changeStatusPublic: 'api/cerere/display-centralizator',
                    exportCSV: 'api/cerere/centralizator-csv',
                },
                tipuriBeneficiar: {
                    getTipuriBeneficiarDt: 'api/nomenclatoare/beneficary-types/list-dt',
                    getTipuriBeneficiar: 'api/nomenclatoare/beneficary-types/list',
                    getTipBeneficiar: 'api/nomenclatoare/beneficary-types/find/', // + id
                    putTipBeneficiar: 'api/nomenclatoare/beneficary-types/update',
                    deleteTipBeneficiar: 'api/nomenclatoare/beneficary-types/delete/', // + id
                    storeTipBeneficiar: 'api/nomenclatoare/beneficary-types/store',
                    changeStatusTipBeneficiarActive: 'api/nomenclatoare/beneficary-types/active/', // + id
                    changeStatusTipBeneficiarPublic: 'api/nomenclatoare/beneficary-types/public/', // + id
                },
                notificari: {
                    email: {
                        getEmailDt: 'api/email/list-dt',
                        downloadEmail: 'api/email/download-pdf/', // + id
                        resendEmail: 'api/email/resend/', // + id
                    },
                    sms: {
                        getSMSDt: 'api/sms/list-dt',
                        resendSMS: 'api/sms/resend/', // + id
                    }
                }
            },
        },
        clients: {
            contacts: {
                getContacts: 'api/client/contact/list',
                getContactById: 'api/client/contact/find/',
                getContactsDT: 'api/client/contact/list-dt',
                getContactsActive: 'api/client/contact/list/active',
                getContactsPublic: 'api/client/contact/list/public',
                updateContact: 'api/client/contact/update',
                changeStatusContactActive: 'api/client/contact/active/',
                changeStatusContactPublic: 'api/client/contact/public/',
                deleteContact: 'api/client/contact/delete/',
                storeContact: 'api/client/contact/store',
            },
            operators: {
                getClientOperatorsDT: 'api/client/user/list-dt',
                getUsersByClient:
                    'api/client/user/getUsersByClient/', //client_id/active (-1, 0, 1)
                storeClientOperator: 'api/client/user/store',
                massStoreClientOperators: 'api/client/user/massStore',
                deleteClientOperator: 'api/client/user/delete/',
            },
            permissions: {
                getUserPermissionsAuthLib:
                    'api/client/permission/user/getUserPermissions/', // + id_client
                listUserPermissions:
                    'api/client/permission/user/listUserPermissions',
                updateAssignedPermissionUser:
                    'api/client/permission/user/updateAssignedPermissionUser',
                listPermissions: 'api/settings/permission/listPermissions',
            },
        },
    },
};

/*
 *  To run this proper configuration, please use: ng serve --configuration=development --port=4100
 */
