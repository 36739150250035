<div class="edit-container">
	<h1 class="edit-title">Adăugare document</h1>

	<form class="edit-card__form" [formGroup]="form" (submit)="submitForm()">
		<div class="row">
			<div class="col">
                <mat-form-field class="w-100">
                    <mat-label>Tip document</mat-label>
                    <mat-select formControlName="doc_type">
                        <mat-option *ngFor="let tip of tipuriDocumente" [value]="tip.id">
                            {{tip.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
			</div>
		</div>
        <div class="solicitare-card__upload my-3">
            <div class="solicitare-card__upload-header">
                <p class="upload-text">Documente</p>
                <button class="upload-btn" (click)="fileInput.click()" type="button">
                    <mat-icon class="upload-icon">cloud_upload</mat-icon>
                    ÎNCARCĂ
                </button>
            </div>
            <div class="solicitare-card__upload-files" *ngIf="form.value.files.length > 0 && !uploading">
                <div class="solicitare-card__upload-file" *ngFor="let uploadedFile of form.value.files; let i = index">
                    <span class="solicitare-card__upload-file-name">
                        {{ (uploadedFile.original_name.length > 30) ? (uploadedFile.original_name | slice: 0:30) + '..' : (uploadedFile.original_name)
                        }}
                    </span>
                    <div>
                        <button class="solicitare-card__upload-file-delete" type="button" matTooltip="Șterge fișierul încărcat" (click)="removeFile(i)">
                            <img class="btn-img" src="../../../assets/icons/delete.svg" alt="" />
                        </button>
                    </div>
                </div>
            </div>
            <mat-spinner diameter="32" style="margin-right: 8px; margin-top: 4px" *ngIf="uploading"></mat-spinner>

            <input type="file" accept="application/pdf" id="upload-file-input-0" #fileInput
                name="fileInput" (change)="addFile($event)" multiple style="display: none">
        </div>

        <div class="row">
			<div class="col d-flex justify-content-between">
				<button class="buttons" type="button" mat-flat-button (click)="closeDialog()">
					{{ helper.dialogConfig.buttonLabels.close }}
				</button>
				<button class="buttons" type="submit" mat-flat-button color="primary" [disabled]="form.invalid">
					{{ helper.dialogConfig.buttonLabels.save }}
				</button>
			</div>
		</div>
	</form>
	<div class="section-spinner position-absolute" *ngIf="isLoading">
		<div class="spinner position-absolute top-50 start-50 translate-middle">
			<mat-spinner></mat-spinner>
			<h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
		</div>
	</div>
</div>