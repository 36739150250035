import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class NotificariService {
    private basePath = environment.interop.basePath;

    public downloadEmailUrl = this.basePath + environment.interop.platform.nomenclatoare.notificari.email.downloadEmail;
    private resendEmailUrl = this.basePath + environment.interop.platform.nomenclatoare.notificari.email.resendEmail;

    private resendSmsUrl = this.basePath + environment.interop.platform.nomenclatoare.notificari.sms.resendSMS;

    constructor(private http: HttpClient) { }

    resendEmail(id: number) {
        return this.http.get(this.resendEmailUrl + id);
    }

    resendSms(id: number) {
        return this.http.get(this.resendSmsUrl + id);
    }
}
