<div class="settings-card">
    <div class="settings-card__header">
        <img src="/assets/images/Group 9.svg" alt="" />
        <div class="settings-card__header-headline">
            <h2 class="m-0 p-0">Notificări</h2>
        </div>
    </div>
</div>

<div class="notificari-page">
    <div class="content-box">
        <div class="row">
            <div class="col-sm-12">
                <div class="section">
                    <div class="section-top">
                        <h3 *ngIf="selectedToggle == 'email'" class="section-title">Notificări Email</h3>
                        <h3 *ngIf="selectedToggle == 'sms'" class="section-title">Notificări SMS</h3>
                        <div class="section-top-actions">
                            <button type="button" class="btn btn-sm btn-info pull-right ms-1" (click)="rerenderChildDT(selectedToggle)">
                                <i class="fa-solid fa-arrows-rotate"></i>
                            </button>
                        </div>
                    </div>

                    <div class="card-container p-2" *ngIf="showCard">
                        <form [formGroup]="searchParamsForm">
                            <div class="row">
                                <div class="col-md-6">
                                    <mat-form-field class="w-100">
                                        <mat-label>Număr cerere</mat-label>
                                        <input matInput type="number" formControlName="numarCerere" />
                                    </mat-form-field>
                                </div>
    
                                <div class="col-md-6">
                                    <mat-form-field class="w-100">
                                        <mat-label>Număr cerere (intern)</mat-label>
                                        <input matInput type="number" formControlName="numarCerereIntern" />
                                    </mat-form-field>
                                </div>
                            </div>

                            <div *ngIf="selectedToggle === 'email'" class="row">
                                <div class="col-md-12">
                                    <mat-form-field class="w-100">
                                        <mat-label>Email</mat-label>
                                        <input matInput type="email" formControlName="email" />
                                    </mat-form-field>
                                </div>
                            </div>
                            <div *ngIf="selectedToggle === 'sms'" class="row">
                                <div class="col-md-12">
                                    <mat-form-field class="w-100">
                                        <mat-label>Telefon</mat-label>
                                        <button disabled="true" type="button" class="country-selector" mat-button matPrefix [matMenuTriggerFor]="menu">
                                            <mat-icon>expand_more</mat-icon>
                                            {{ currentOption.flag }} {{ currentOption.dial_code }}
                                        </button>
                                        <mat-menu #menu="matMenu">
                                            <button mat-menu-item (click)="changeCountry(optionRom)">
                                                <span class="flag-wrapper">🇷🇴</span>
                                                <span class="label-wrapper">
                                                    {{ optionRom.name }} {{ optionRom.dial_code }}
                                                </span>
                                            </button>
                                            <mat-divider class="divider"></mat-divider>
                                            <button mat-menu-item *ngFor="let option of options"(click)="changeCountry(option)">
                                                <span class="flag-wrapper">{{ option.flag }}</span>
                                                <span class="label-wrapper">
                                                    {{ option.name }} {{ option.dial_code }}
                                                </span>
                                            </button>
                                        </mat-menu>
                                        <input type="text" matInput formControlName="telefon" placeholder="740999999"/>
                                    </mat-form-field>
                                </div>
                            </div>
                        </form>
                        <div class="row w-100">
                            <div class="col d-flex justify-content-start">
                                <button (click)="toggleCard()" type="button" class="btn btn-sm btn-warning closeBtn">Închide</button>
                            </div>
                            <div class="col buttons-submit">
                                <button (click)="resetForm()" type="button" class="btn btn-sm btn-secondary">
                                    Resetează
                                </button>
                                <button (click)="applyFilters()" type="submit" class="btn btn-sm btn-primary ms-2">
                                    Aplică
                                </button>
                            </div>
                        </div>
                        <div class="section-spinner position-absolute" *ngIf="isFilterLoading">
                            <div class="spinner position-absolute top-50 start-50 translate-middle">
                                <mat-spinner></mat-spinner>
                                <h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="p-2">
                        <div class="d-flex justify-content-start">
                            <button class="action-button d-flex align-items-center" (click)="toggleCard()" [class.active]="showCard">
                                <img src="assets/images/icons8-filter-edit-50.png" alt="" />
                                <span>Filtre</span>
                            </button>
                        </div>
                    </div>   
                    <div class="request-filter-container">
                        <div class="request-filter">
                            <mat-button-toggle-group name="fontStyle" aria-label="Font Style" (change)="onToggleChange($event)" [value]="selectedToggle">
                                <mat-button-toggle *ngFor="let option of categoryToggleOptions" [value]="option.id">
                                    {{ option.value}}
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>
                    <div *ngIf="selectedToggle == 'email'">
                        <app-email [searchParamsForm]="searchParamsForm"></app-email>
                    </div>
                    <div *ngIf="selectedToggle == 'sms'">
                        <app-sms [searchParamsForm]="searchParamsForm"></app-sms>
                    </div>
                    <div class="section-spinner position-absolute" *ngIf="isLoading">
                        <div class="spinner position-absolute top-50 start-50 translate-middle">
                            <mat-spinner></mat-spinner>
                            <h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
