import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificariComponent } from './notificari.component';
import { MaterialModule } from 'src/app/material.module';
import { EmailComponent } from './tabele-notificari/email/email.component';
import { SmsComponent } from './tabele-notificari/sms/sms.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [NotificariComponent, EmailComponent, SmsComponent],
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [NotificariComponent]
})
export class NotificariModule { }
