<div class="table-container">
    <table id="datatable" class="row-border hover custom-dt w-100" datatable [dtOptions]="dtOptions"
        [dtTrigger]="dtTrigger">
    </table>
</div>
<div class="section-spinner position-absolute" *ngIf="isLoading">
    <div class="spinner position-absolute top-50 start-50 translate-middle">
        <mat-spinner></mat-spinner>
        <h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
    </div>
</div>