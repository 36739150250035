<div class="edit-container">
	<h1 *ngIf="data.type === 'email'" class="edit-title">Actualizare email</h1>
	<h1 *ngIf="data.type === 'telefon'" class="edit-title">Actualizare număr de telefon</h1>
	<h1 *ngIf="data.type === 'nume_reprezentant'" class="edit-title">Actualizare denumire reprezentant</h1>
	<h1 *ngIf="data.type === 'tip_beneficiar'" class="edit-title">Actualizare tip beneficiar</h1>

	<form class="edit-card__form" [formGroup]="form" (submit)="submitForm()">
		<div class="row">
			<div class="col">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label *ngIf="data.type === 'email'">Email</mat-label>
					<mat-label *ngIf="data.type === 'telefon'">Telefon</mat-label>
					<mat-label *ngIf="data.type === 'nume_reprezentant'">Denumire Reprezentant</mat-label>
					<mat-label *ngIf="data.type === 'tip_beneficiar'">Tip beneficiar</mat-label>
					<input *ngIf="data.type !== 'tip_beneficiar'" type="text" matInput formControlName="value" />
                    <mat-select *ngIf="data.type === 'tip_beneficiar'" formControlName="value">
                        <mat-option value="-1">-- Alegeți --</mat-option>
                            <mat-option *ngFor="let beneficiar of beneficiari | async" [value]="beneficiar.id">
                                {{ beneficiar.name }}
                            </mat-option>
                    </mat-select>
					<mat-error *ngIf="!form.controls['value'].valid && form.controls['value'].touched">
						Câmp obligatoriu
					</mat-error>
				</mat-form-field>
			</div>
		</div>

        <div class="row">
			<div class="col d-flex justify-content-between">
				<button class="buttons" type="button" mat-flat-button (click)="closeDialog()">
					{{ helper.dialogConfig.buttonLabels.close }}
				</button>
				<button class="buttons" type="submit" mat-flat-button color="primary" [disabled]="form.invalid">
					{{ helper.dialogConfig.buttonLabels.save }}
				</button>
			</div>
		</div>
	</form>
	<div class="section-spinner position-absolute" *ngIf="isLoading">
		<div class="spinner position-absolute top-50 start-50 translate-middle">
			<mat-spinner></mat-spinner>
			<h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
		</div>
	</div>
</div>