import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CetateanComponent } from './cetatean.component';
import { RequestsComponent } from './requests/requests.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { RequestDetailsComponent } from './requests/request-details/request-details.component';
import { SubsemnatulModule } from 'src/app/shared/subsemnatul/subsemnatul.module';
import { RequestHistoryComponent } from './requests/request-history/request-history.component';
import { UpdateRequestStatusComponent } from './requests/update-request-status/update-request-status.component';
import { AssignRequestComponent } from './requests/assign-request/assign-request.component';
import { ActiuniCerereCardModule } from 'src/app/shared/actiuni-cerere-card/actiuni-cerere-card.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CereriIstoricComponent } from './requests/request-details/cereri-istoric/cereri-istoric.component';
import { ActualizareCerereComponent } from './requests/request-details/actualizare-cerere/actualizare-cerere.component';
import { CerereDetaliiSabloaneComponent } from './requests/request-details/sabloane/cerere-detalii-sabloane.component';
import { PipesModule } from 'src/app/utils/pipes/pipes.module';
import { ModalInchidereLucrareComponent } from './requests/request-details/modal-inchidere-lucrare/modal-inchidere-lucrare.component';
import { UpdateRequestPriorityComponent } from './requests/update-request-priority/update-request-priority.component';
import { EditDialogComponent } from './requests/request-details/edit-dialog/edit-dialog.component';
import { NotificareEmailComponent } from './requests/request-details/notificare-email/notificare-email.component';
import { AddEvaluatorDialogComponent } from './requests/request-details/add-evaluator-dialog/add-evaluator-dialog.component';
import { AddDocumentDialogComponent } from './requests/request-details/add-document-dialog/add-document-dialog.component';

@NgModule({
    declarations: [
        CetateanComponent,
        RequestsComponent,
        RequestDetailsComponent,
        RequestHistoryComponent,
        UpdateRequestStatusComponent,
        AssignRequestComponent,
        CereriIstoricComponent,
        ActualizareCerereComponent,
        CerereDetaliiSabloaneComponent,
        ModalInchidereLucrareComponent,
        UpdateRequestPriorityComponent,
        EditDialogComponent,
        NotificareEmailComponent,
        AddEvaluatorDialogComponent,
        AddDocumentDialogComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        SubsemnatulModule,
        ActiuniCerereCardModule,
        BrowserAnimationsModule,
        PipesModule,
    ],
    exports: [CetateanComponent, RequestsComponent],
})
export class CetateanModule { }
