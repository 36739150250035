<div class="navbar">
    <!-- This is the hamburger menu that triggers below 350px -->
    <div class="mobile-version__drawer-container">
        <button mat-button class="menu-button" (click)="sidenav.toggle()">
            <mat-icon>{{ isMenuOpen ? "menu_open" : "menu" }}</mat-icon>
        </button>
        <mat-sidenav-container class="sidenav-container">
            <mat-sidenav #sidenav autoFocus="false" mode="over" [(opened)]="isMenuOpen" class="sidenav" (click)="onSidenavClick()">
                <div class="drawer__buttons">
                    <mat-accordion class="sidenav-accordion">
                        <p *ngIf="permissionsService.hasPermission('solicitari.list')" routerLink="/requests" class="settings-option-menu"
                            [class.menu-active]="'/requests' === activeRoute" (click)="setActiveRoute('/requests')">
                            <img src="/assets/icons/cereri.svg" alt="" />
                            <span>Solicitări</span>
                        </p>
                        <p *ngIf="permissionsService.hasPermission('sabloane-cerere.list')" class="settings-option-menu" routerLink="/sabloane-requests"
                            [class.menu-active]="'/sabloane-requests' === activeRoute" (click)="setActiveRoute('/sabloane-requests')">
                            <img src="/assets/icons/drawer.svg" alt="" />
                            <span>Șabloane Solicitări</span>
                        </p>
                        <p *ngIf="permissionsService.hasPermission('gallery.list')" routerLink="/galerie" class="sidenav-link" [class.menu-active]="'/galerie' === activeRoute"
                            (click)="setActiveRoute('/galerie')">
                            <img src="/assets/icons/gallery.svg" alt="" />
                            <span>Galerie</span>
                        </p>
                        <p *ngIf="permissionsService.hasPermission('clients.list')" routerLink="/clienti" class="sidenav-link" [class.menu-active]="'/clienti' === activeRoute"
                            (click)="setActiveRoute('/clienti')">
                            <img src="/assets/icons/clients.svg" alt="" />
                            <span>Instituții</span>
                        </p>
                        <p *ngIf="permissionsService.hasPermission('uat.list')" routerLink="/uat" class="sidenav-link" [class.menu-active]="'/uat' === activeRoute"
                            (click)="setActiveRoute('/uat')">
                            <img src="/assets/icons/clients.svg" alt="" />
                            <span>UAT</span>
                        </p>
                        <p *ngIf="permissionsService.hasPermission('centralizator.list')" routerLink="/centralizator" class="sidenav-link" [class.menu-active]="'/centralizator' === activeRoute"
                            (click)="setActiveRoute('/centralizator')">
                            <img src="/assets/icons/borderou.svg" alt="" />
                            <span>Centralizator</span>
                        </p>
                        <p *ngIf="permissionsService.hasPermission('raports.list')" routerLink="/rapoarte" class="sidenav-link" [class.menu-active]="'/rapoarte' === activeRoute"
                            (click)="setActiveRoute('/rapoarte')">
                            <img src="/assets/icons/rapoarte.svg" alt="" />
                            <span>Live Report</span>
                        </p>
                        <p *ngIf="permissionsService.hasPermission('notificari.list')" routerLink="/notificari" class="sidenav-link" [class.menu-active]="'/notificari' === activeRoute"
                            (click)="setActiveRoute('/notificari')">
                            <img src="/assets/icons/notificari.svg" alt="" />
                            <span>Notificări</span>
                        </p>
                        <p routerLink="/settings" class="sidenav-link" [class.menu-active]="'/settings' === activeRoute" (click)="setActiveRoute('/settings')">
                            <img src="/assets/icons/settings.svg" alt="" />
                            <span>Setări</span>
                        </p>

                        <mat-expansion-panel class="sidenav-accordion" (opened)="panelOpenState2 = true" (closed)="panelOpenState2 = false">
                            <mat-expansion-panel-header class="sidenav-accordion" [class.hide-line]="panelOpenState2">
                                <mat-panel-title class="sidenav-accordion">
                                    <div class="panel-title">
                                        <img src="/assets/icons/administrative.svg" alt="" />
                                        <span>Administrativ</span>
                                    </div>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p routerLink="/admin-settings" class="settings-option-menu" [class.menu-active]="'/admin-settings' === activeRoute" (click)="setActiveRoute('/admin-settings')">
                                <img src="/assets/icons/settings.svg" alt="" />
                                <span>Setări</span>
                            </p>
                        </mat-expansion-panel>
                    </mat-accordion>

                    <div class="footer">
                        <div class="logout-button" (click)="logout()">
                            <img src="assets/icons/logout.svg" alt="Deconectare" />
                            <div class="logout-button--text">Deconectare</div>
                        </div>
                    </div>
                </div>
            </mat-sidenav>
            <mat-sidenav-content>
                <main>
                    <!-- <router-outlet></router-outlet> -->
                </main>
            </mat-sidenav-content>
        </mat-sidenav-container>
    </div>



    <button type="button" class="admin" [class.admin-active]="isPopoverOpen" [popover]="popTemplate" #adminPopover="bs-popover"
        [adaptivePosition]="true" container="body" boundariesElement="scrollParent" placement="auto" (click)="togglePopover()">
        <div class="username">{{ username }}</div>
        <div class="username__icon">
            <div class="username__icon--letters">{{ usernameLetters }}</div>
        </div>
    </button>

    <ng-template #popTemplate>
        <div class="popover">
            <div class="row">
                <div class="col d-flex flex-column">
                    <div class="content d-flex flex-column">
                        <div class="username">{{ username }}</div>
                        <div class="description">Membru din data de {{memberSince | date }}</div>
                    </div>
                    <div class="actions d-flex justify-content-between mt-2">
                        <button (click)="closePopover()" type="button" class="btn btn-light">Închide</button>
                        <button (click)="openResetPasswordComp()" type="button" class="btn btn-light">Profil</button>
                        <button (click)="logout()" type="button" class="btn btn-light">Deconectare</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <div class="notification" [popover]="popTemplateNotifications" [adaptivePosition]="true" #notificationsPopover="bs-popover"
        placement="bottom" container="body" boundariesElement="scrollParent" (click)="toggleNotificationsPopover()">
        <div class="notifications" [ngClass]="{ 'move-up': !userNotifications && !isLoading }">
            <mat-spinner color="primary" diameter="40" *ngIf="isLoading"></mat-spinner>
            <img src="assets/icons/icon-bell.svg" alt="Notificari" />
            <div class="notifications--chip" [ngClass]="{ 'hide-chip': isLoading }">
                <span *ngIf="!isLoading && userNotifications">{{ userNotifications }}</span>
            </div>
        </div>

    </div>

    <ng-template #popTemplateNotifications>
        <p class="title">Aveți {{ userNotifications }} notificări</p>
        <div class="notification-popup d-flex flex-column">
            <div class="notification-message-container">
                <button type="button" class=" btn btn-light subtitle d-flex justify-content-start align-items-center gap-2" (click)="onButtonClick({id: '1', name: 'Cereri'})">
                    <i class="fa-solid fa-envelope info"></i> {{notification['urbanism_modified']}} Solicitări necesită atenția dvs.
                </button>

                <button type="button" class="btn btn-light subtitle d-flex justify-content-start align-items-center gap-2" (click)="onButtonClick({id: '2', name: 'Mesaje interne'})">
                    <i class="fa-solid fa-triangle-exclamation warning"></i> {{notification['urbanism_messages']}} Mesaje interne pentru dvs.
                </button>
            </div>
        </div>
    </ng-template>
</div>