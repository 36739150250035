import { Component, Inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from '../../auth.service';
import {
    IChangePasswordForceUserPayload
} from '../change-password-force.interface';

import { environment } from 'src/environments/environment';

import { helper } from 'src/environments/helper'
import { containsLowerCaseValidator } from 'src/app/shared/validators/containsLowerCaseValidator';
import { containsUpperCaseValidator } from 'src/app/shared/validators/containsUpperCaseValidator';
import { containsSpecialCharValidator } from 'src/app/shared/validators/containsSpecialCharValidator';
import { containsNumberValidator } from 'src/app/shared/validators/containsNumberValidator';

@Component({
    selector: 'app-change-password-force',
    templateUrl: './change-password-force.component.html',
    styleUrls: ['./change-password-force.component.scss']
})
export class ChangePasswordForceComponent {
    // general error
    errorTitle: string = helper.dialogConfig.headers.errorAtentie;
    errorIcon: string = helper.dialogConfig.icons.error;
    errorType: string = helper.dialogConfig.icons.error;

    hideTP = true; // temporary password
    hideNP = true; // new password
    hideCP = true; // confirm password

    isLoading = false;
    form: FormGroup

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: IChangePasswordForceUserPayload,
        private dialogRef: MatDialogRef<ChangePasswordForceComponent>,
        private notificationService: NotificationService,
        private authService: AuthService
    ) {

        this.form = new FormGroup({
            id_user: new FormControl(this.data.id_user, [Validators.required]),
            email: new FormControl(this.data.email, []),
            username: new FormControl(this.data.username, []),
            temporary_password: new FormControl(this.data.temporary_password, [
                Validators.required,
                Validators.minLength(environment.config.validatorsAccrossApp.minPassword),
                Validators.maxLength(environment.config.validatorsAccrossApp.maxPassword),
                containsLowerCaseValidator(),
                containsUpperCaseValidator(),
                containsSpecialCharValidator(),
                containsNumberValidator()
            ]),
            new_password: new FormControl(this.data.new_password, [
                Validators.required,
                Validators.minLength(environment.config.validatorsAccrossApp.minPassword),
                Validators.maxLength(environment.config.validatorsAccrossApp.maxPassword),
                containsLowerCaseValidator(),
                containsUpperCaseValidator(),
                containsSpecialCharValidator(),
                containsNumberValidator()
            ]),
            confirm_new_password: new FormControl(this.data.confirm_new_password, [
                Validators.required,
                Validators.minLength(environment.config.validatorsAccrossApp.minPassword),
                Validators.maxLength(environment.config.validatorsAccrossApp.maxPassword),
                containsLowerCaseValidator(),
                containsUpperCaseValidator(),
                containsSpecialCharValidator(),
                containsNumberValidator(),
                this.passwordMatchValidator()
            ])
        });
        // , { validators: this.checkPasswords });

        console.log(this.form.controls['temporary_password'].errors);
    }

    closeDialog() {
        this.dialogRef?.close({
            success: false,
        });
    }

    passwordMatchValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const form = control.parent;
            if (!form) return null;

            const password = form.get('new_password')?.value;
            const confirmPassword = control.value;

            return password === confirmPassword ? null : { mismatch: true };
        };
    }

    // checkPasswords(group: FormGroup) {
    // 	const password = group.get('new_password').value;
    // 	const confirmPassword = group.get('confirm_new_password').value;
    // 	return password === confirmPassword ? null : { notSame: true };
    //   }

    submitForm() {
        if (this.isLoading) return;

        this.isLoading = true;

        this.authService
            .updatePasswordForce(this.form.value)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
                next: () => {
                    this.dialogRef.close({ success: true });
                },
                error: (err: any) => {
                    let msg
                    try {
                        // try to catch the message from server
                        msg = err.error.errors.message[0]
                    } catch (error) {
                        // controll output message
                        msg = helper.dialogConfig.generalMessages.error
                    }
                    this.notificationService.warningSwal(
                        helper.dialogConfig.headers.errorAtentie,
                        msg,
                        this.errorIcon
                    );
                },
            });
    }

    onHttpError(res: any) {
        if (res.errors && res.errors.message && res.errors.message.length > 0) {
            res.errors.message.forEach((msg: string) => {
                this.notificationService.warningSwal(
                    this.errorTitle,
                    msg,
                    this.errorIcon
                );
            });
        } else {
            let msg
            try {
                // try to catch the message from server
                msg = res.error.errors.message[0]
            } catch (error) {
                // controll output message
                msg = helper.dialogConfig.generalMessages.error
            }
            this.notificationService.warningSwal(
                this.errorTitle,
                msg,
                this.errorIcon
            );
        }
    }

    getPasswordErrorCount(control: string): number {
        const passwordControl = this.form.controls[control];
        let errorCount = 0;

        if (passwordControl.touched) {
            if (passwordControl.hasError('required')) {
                errorCount++;
            }

            if (passwordControl.hasError('noLowerCase')) {
                errorCount++;
            }

            if (passwordControl.hasError('noUpperCase')) {
                errorCount++;
            }

            if (passwordControl.hasError('noSpecialChar')) {
                errorCount++;
            }

            if (passwordControl.hasError('noNumber')) {
                errorCount++;
            }

            if (passwordControl.hasError('minlength')) {
                errorCount++;
            }

            if (passwordControl.hasError('maxlength')) {
                errorCount++;
            }

            if (passwordControl.hasError('mismatch')) {
                errorCount++;
            }
        }

        return errorCount;
    }
}
