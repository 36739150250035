import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Judet, JudetUpdate } from './judet.interface';
import { catchError, map, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class JudeteService {
    private token!: string;
    private httpOptions: any;

    private basePath = environment.interop.basePath;
    private getJudeteDTUrl =
        this.basePath +
        environment.interop.platform.nomenclatoare.judete.getJudeteDT;
    private storeJudeteUrl =
        this.basePath +
        environment.interop.platform.nomenclatoare.judete.storejudet;
    private updateJudeteUrl =
        this.basePath +
        environment.interop.platform.nomenclatoare.judete.updateJudete;
    private deleteJudetUrl =
        this.basePath +
        environment.interop.platform.nomenclatoare.judete.deleteJudet;
    private getJudetePublicUrl =
        this.basePath +
        environment.interop.platform.nomenclatoare.judete.getJudetePublic;
    private getJudeteActiveUrl =
        this.basePath +
        environment.interop.platform.nomenclatoare.judete.getJudeteActive;

    private changeStatusActiveUrl =
        this.basePath +
        environment.interop.platform.nomenclatoare.judete.changeStatusJudeteActive;
    private changeStatusPublicUrl =
        this.basePath +
        environment.interop.platform.nomenclatoare.judete.changeStatusJudetePublic;

    private getRegionsUrl = this.basePath + environment.interop.platform.nomenclatoare.judete.getRegions;

    constructor(private http: HttpClient) { }

    changeStatusActive(id: number) {
        return this.http.put(this.changeStatusActiveUrl + id, {}, this.httpOptions);
    }

    getJudeteDT() {
        return this.http.get(this.getJudeteDTUrl, this.httpOptions);
    }

    addJudet(judetName: Judet) {
        return this.http.post(this.storeJudeteUrl, judetName, this.httpOptions);
    }

    updateJudet(judet: JudetUpdate) {
        return this.http.put(this.updateJudeteUrl, judet, this.httpOptions);
    }

    deleteJudet(judetId: number) {
        return this.http.delete(this.deleteJudetUrl + judetId);
    }

    getJudeteActive() {
        return this.http
            .get<{ errors: boolean; data: any[]; status_code: number }>(
                this.getJudeteActiveUrl
            )
            .pipe(
                map((response) =>
                    response.data.map((item) => ({
                        id: item.id,
                        name: item.name,
                    }))
                ),
                catchError((error) => {
                    console.error('Error in get judete active:', error);
                    return throwError(error);
                })
            );
    }

    getJudetePublic() {
        return this.http.get(this.getJudetePublicUrl, this.httpOptions);
    }

    getRegions() {
        return this.http.get(this.getRegionsUrl, this.httpOptions);
    }

    changeStatuseActive(id: number) {
        return this.http.put(this.changeStatusActiveUrl + id, {}, this.httpOptions);
    }

    changeStatusePublic(id: number) {
        return this.http.put(this.changeStatusPublicUrl + id, {}, this.httpOptions);
    }

    setToken(token: string) {
        this.token = token;
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.token}`,
            }),
        };
    }
}
