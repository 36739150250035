import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { helper } from 'src/environments/helper';
import { PermissionsService } from 'src/app/services/permissions.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { EmailComponent } from './tabele-notificari/email/email.component';
import { SmsComponent } from './tabele-notificari/sms/sms.component';
import { FormControl, FormGroup } from '@angular/forms';
import { countriesList, ICountryOption, OptionRom } from 'src/app/shared/countries-list';

@Component({
    selector: 'app-notificari',
    templateUrl: './notificari.component.html',
    styleUrls: ['./notificari.component.scss']
})
export class NotificariComponent implements OnInit {
    @ViewChild(EmailComponent) appEmail!: EmailComponent;
    @ViewChild(SmsComponent) appSms!: SmsComponent;

    @ViewChild(DataTableDirective, { static: false })
    dtElement!: DataTableDirective;
    helper = helper;

    isLoading = false;
    isFilterLoading = false;
    showCard = false;

    dtOptions: any = {};
    dtTrigger: Subject<any> = new Subject<any>();
    dtInstance!: DataTables.Api;

    searchParamsForm!: FormGroup;

    initialFormValues = {
        numarCerere: '',
        numarCerereIntern: '',
        email: '',
        telefon: '',
    };

    buildSearchParamsForm() {
        this.searchParamsForm = new FormGroup({
            numarCerere: new FormControl(''),
            numarCerereIntern: new FormControl(''),
            email: new FormControl(''),
            telefon: new FormControl(''),
        });
    }

    optionRom: ICountryOption = OptionRom;
    currentOption = this.optionRom;
    options: ICountryOption[] = countriesList;

    // Values for category filtering
    categoryToggleOptions = [
        { id: 'email', value: 'Email' },
        { id: 'sms', value: 'SMS' },
    ];

    selectedToggle: string = this.categoryToggleOptions[0].id;

    changeCountry(option: ICountryOption) {
        this.currentOption = option;
    }

    resetForm() {
        this.searchParamsForm.reset(this.initialFormValues);

        this.rerenderChildDT(this.selectedToggle);
        this.showCard = true;
    }

    applyFilters(): void {
        this.rerenderChildDT(this.selectedToggle);
    }

    toggleCard() {
        this.showCard = !this.showCard;
    }

    constructor(
        private permissionsService: PermissionsService,
    ) { }

    ngOnInit(): void {
        this.buildSearchParamsForm();
    }

    onToggleChange(event: MatButtonToggleChange) {
        this.selectedToggle = event.value;
    }

    rerenderChildDT(child: string) {
        switch (child) {
            case 'email':
                this.appEmail.rerenderDT();
                break;
            case 'sms':
                this.appSms.rerenderDT();
        }
    }

    filterCategoryOptions() {
        const permissionMapping: { [key: string]: string } = {
            "email": "notificari.email.list",
            "sms": "notificari.sms.list",
        };

        // Filter categoryToggleOptions based on permissions
        this.categoryToggleOptions = this.categoryToggleOptions.filter(option =>
            this.permissionsService.hasPermission(permissionMapping[option.id.toString()])
        );

        // Initialize selectedCategory after filtering
        this.selectedToggle = this.categoryToggleOptions[0]?.id;
    }
}
