<div class="title p-2 background-white">
    <div class="row">
        <div class="col d-flex justify-content-start align-items-center">
            <h1 class="mx-1 d-flex align-items-center">
                <span>Info solicitare {{cerere?.reg_number ? cerere?.reg_number : 'N/A' }} / {{ cerere?.created_at | date:
                    'dd.MM.yyyy' }}</span>
                <span class="text-secondary m-2">#{{ cerere?.id }}</span>
                <button type="button" class="btn btn-small btn-primary me-3" (click)="getRequestInfo()">
                    <i class="fa-solid fa-arrows-rotate"></i>
                    <span class="ms-2">Reîncarcă informații</span>
                </button>
                <ng-container *ngIf="cerere?.validated_date && !cerere?.cancel_date">
                    <h4 class="d-flex align-items-center gap-1" style="border-radius: 10px; padding: 4px 8px; color: white; background: green; margin: 0;"><i class="fa-solid fa-flag" style="color: white;"></i> Eligibil</h4>
                </ng-container>
                <ng-container *ngIf="cerere?.cancel_date && cerere?.cancel_type === 1">
                    <h4 class="d-flex align-items-center gap-1" style="border-radius: 10px; padding: 4px 8px; color: white; background: orange; margin: 0;"><i class="fa-solid fa-flag" style="color: white;"></i> NE-Eligibil</h4>
                </ng-container>
                <ng-container *ngIf="cerere?.cancel_date && cerere?.cancel_type === 2">
                    <h4 class="d-flex align-items-center gap-1" style="border-radius: 10px; padding: 4px 8px; color: white; background: red; margin: 0;"><i class="fa-solid fa-flag" style="color: white;"></i> NE-Eligibil</h4>
                </ng-container>
            </h1>
        </div>
        <div class="col d-flex justify-content-end">
            <button type="button" class="action-btn" (click)="closeDialog()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
</div>

<div class="row p-3 responsive-column background-white">
    <div class="col-8 responsive-box-info">
        <div class="g-0">
            <div class="row">
                <div class="col ">
                    <div class="box-wrapper box-mesagerie">
                        <div class="box-content-header">
                            <div class="row p-1">
                                <div class="col">
                                    <mat-card-title>
                                        <i class="fa-solid fa-book"></i>
                                        <span>Conținut solicitare</span>
                                    </mat-card-title>
                                </div>
                            </div>
                        </div>
                        <div class="box-content-body">
                            <div class="col">
                                <div *ngIf="cerere?.type_slug === 'depunere_proiect'" class="row py-1 mx-1 bg">
                                    <div class="col">
                                        <div class="fw-bold">Tip UAT</div>
                                    </div>
                                    <div class="col">
                                        <div class="fw-bold">Județ</div>
                                    </div>
                                    <div class="col">
                                        <div class="fw-bold">Denumire UAT - SIRUTA</div>
                                    </div>
                                </div>
                                <div *ngIf="cerere?.type_slug === 'depunere_proiect'" class="row py-1 mx-1">
                                    <div class="col">
                                        <div class="d-flex align-items-center gap-2">
                                            <span>
                                                {{cerere?.tip_beneficiar}}
                                            </span>
                                            <span class="d-flex align-items-center gap-2">
                                                <i *ngIf="cerere?.type_slug === 'depunere_proiect' 
                                                    && ((permissionsService.hasPermission('solicitari.view.actualizare-informatii')
                                                    && permissionsService.hasPermission('solicitari.functie-operator.manager') 
                                                    && userId == cerere?.user_assigned_id))
                                                    || permissionsService.isUserAdmin" 
                                                    style="cursor: pointer;" class="fa fa-pencil text-warning" 
                                                    (click)="openEditDialog(cerere?.tip_beneficiar_id, 'tip_beneficiar')">
                                                </i>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div>{{cerere?.judet || 'n/a'}}</div>
                                    </div>
                                    <div class="col">
                                        <div>{{cerere?.denumire_uat || 'n/a'}} - {{cerere?.cif_uat || 'n/a'}}</div>
                                    </div>
                                </div>
                                

                                <div class="row py-1 mx-1 bg">
                                    <div class="col">
                                        <div *ngIf="cerere?.type_slug === 'depunere_proiect'" class="fw-bold">Denumire responsabil UAT</div>
                                        <div *ngIf="cerere?.type_slug === 'suport_tehnic'" class="fw-bold">Denumire responsabil</div>
                                    </div>
                                    <div class="col">
                                        <div class="fw-bold">Email</div>
                                    </div>
                                    <div class="col">
                                        <div class="fw-bold">Telefon</div>
                                    </div>
                                </div>
                                <div class="row py-1 mx-1">
                                    <div class="col">
                                        <div class="d-flex align-items-center gap-2">
                                            <span>
                                                {{cerere?.nume_reprezentant}}
                                            </span>
                                            <span class="d-flex align-items-center gap-2">
                                                <i *ngIf="cerere?.type_slug === 'depunere_proiect' 
                                                    && ((permissionsService.hasPermission('solicitari.view.actualizare-informatii')
                                                    && permissionsService.hasPermission('solicitari.functie-operator.manager') 
                                                    && userId == cerere?.user_assigned_id))
                                                    || permissionsService.isUserAdmin" 
                                                    style="cursor: pointer;" class="fa fa-pencil text-warning" 
                                                    (click)="openEditDialog(cerere?.nume_reprezentant, 'nume_reprezentant')">
                                                </i>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="d-flex align-items-center gap-2">
                                            <span>
                                                {{cerere?.email || 'n/a'}}
                                            </span>
                                            <span class="d-flex align-items-center gap-2">
                                                <i *ngIf="cerere?.type_slug === 'depunere_proiect' 
                                                    && ((permissionsService.hasPermission('solicitari.view.actualizare-informatii')
                                                    && permissionsService.hasPermission('solicitari.functie-operator.manager') 
                                                    && userId == cerere?.user_assigned_id))
                                                    || permissionsService.isUserAdmin" 
                                                    style="cursor: pointer;" class="fa fa-pencil text-warning" (click)="openEditDialog(cerere?.email, 'email')">
                                                </i>
                                                <i *ngIf="cerere?.type_slug === 'depunere_proiect' 
                                                    && ((permissionsService.hasPermission('solicitari.view.resend-notification')
                                                    && permissionsService.hasPermission('solicitari.functie-operator.manager') 
                                                    && userId == cerere?.user_assigned_id))
                                                    || permissionsService.isUserAdmin" 
                                                    style="cursor: pointer;" class="fa-solid fa-share text-info" title="Retrimitere notificare email" (click)="resendEmailNotification(cerere?.id)">
                                                </i>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="d-flex align-items-center gap-2">
                                            <span>
                                                {{cerere?.telefon || 'n/a'}} 
                                            </span>
                                            <i *ngIf="cerere?.type_slug === 'depunere_proiect' 
                                                && ((permissionsService.hasPermission('solicitari.view.actualizare-informatii')
                                                && permissionsService.hasPermission('solicitari.functie-operator.manager') 
                                                && userId == cerere?.user_assigned_id))
                                                || permissionsService.isUserAdmin"
                                                style="cursor: pointer;" class="fa fa-pencil text-warning" (click)="openEditDialog(cerere?.telefon, 'telefon')">
                                            </i>
                                            <i *ngIf="cerere?.type_slug === 'depunere_proiect' 
                                                && ((permissionsService.hasPermission('solicitari.view.resend-notification')
                                                && permissionsService.hasPermission('solicitari.functie-operator.manager') 
                                                && userId == cerere?.user_assigned_id))
                                                || permissionsService.isUserAdmin" 
                                                style="cursor: pointer;" class="fa-solid fa-share text-info" title="Retrimitere notificare SMS" (click)="resendSmsNotification()">
                                            </i>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="cerere?.type_slug === 'suport_tehnic'" class="row py-1 mx-1 bg">
                                    <div class="col">
                                        <div class="fw-bold">Subiect</div>
                                    </div>
                                    <div class="col">
                                        <div class="fw-bold">Mesaj</div>
                                    </div>
                                    <div class="col"></div>
                                </div>
                                <div *ngIf="cerere?.type_slug === 'suport_tehnic'" class="row py-1 mx-1">
                                    <div class="col">
                                        <div>{{cerere?.subject || 'n/a'}}</div>
                                    </div>
                                    <div class="col">
                                        <div>{{cerere?.description || 'n/a'}}</div>
                                    </div>
                                    <div class="col"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="cerere?.type_slug === 'depunere_proiect'" 
        class="g-0">
            <div class="row" *ngIf="permissionsService.isUserAdmin == true 
            || permissionsService.hasPermission('solicitari.functie-operator.manager')">
                <div class="col ">
                    <div class="box-wrapper box-mesagerie">
                        <div class="box-content-header">
                            <div class="row p-1">
                                <div class="col">
                                    <mat-card-title>
                                        <i class="fa-solid fa-bell"></i>
                                        <span>Notificări</span>
                                    </mat-card-title>
                                </div>
                            </div>
                        </div>
                        <div class="box-content-body">
                            <div class="request-filter-container p-2">
                                <div class="request-filter">
                                    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" (change)="onToggleChange($event)" value="email">
                                        <mat-button-toggle value="email">Email</mat-button-toggle>
                                        <mat-button-toggle value="sms">SMS</mat-button-toggle>
                                    </mat-button-toggle-group>
                                </div>
                            </div>
                            <div *ngIf="selectedToggle === 'email'" class="col">
                                <div class="row py-1 mx-1 bg">
                                    <div class="col-1">
                                        <div class="fw-bold">Crt.</div>
                                    </div>
                                    <div class="col">
                                        <div class="fw-bold">Subiect</div>
                                    </div>
                                    <div class="col">
                                        <div class="fw-bold">Email</div>
                                    </div>
                                    <div class="col">
                                        <div class="fw-bold">Trimis</div>
                                    </div>
                                    <div class="col">
                                        <div class="fw-bold">Acțiuni</div>
                                    </div>
                                </div>
                                <div *ngFor="let email of emailsList; let i = index" class="row py-1 mx-1">
                                    <div class="col-1">
                                        <div>{{i + 1}}</div>
                                    </div>
                                    <div class="col">
                                        <div>{{email.subject || 'n/a'}}</div>
                                    </div>
                                    <div class="col">
                                        <div>{{email.email_sent_to || 'n/a'}}</div>
                                    </div>
                                    <div class="col">
                                        <div>{{formatRomanianDate(email.created_at) || 'n/a'}}</div>
                                    </div>
                                    <div class="col">
                                        <button type="button" class="btn btn-light open-btn" (click)="downloadEmail(email.id)">
                                            <img src="/assets/icons/view.svg" alt="VIZUALIZAȚI" />
                                            <span class="view-btn-text">VIZUALIZAȚI</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="selectedToggle === 'sms'" class="col">
                                <div class="row py-1 mx-1 bg">
                                    <div class="col">
                                        <div class="fw-bold">Crt.</div>
                                    </div>
                                    <div class="col">
                                        <div class="fw-bold">OTP</div>
                                    </div>
                                    <div class="col">
                                        <div class="fw-bold">Trimis</div>
                                    </div>
                                    <div class="col">
                                        <div class="fw-bold">Telefon</div>
                                    </div>
                                </div>
                                <div *ngFor="let otp of otpList" class="row py-1 mx-1">
                                    <div class="col">
                                        <div>{{otp.code_index || 'n/a'}}</div>
                                    </div>
                                    <div class="col">
                                        <div>{{otp.code || 'n/a'}}</div>
                                    </div>
                                    <div class="col">
                                        <div>{{otp.code_time}}</div>
                                    </div>
                                    <div class="col">
                                        <div>{{otp.code_sent_to}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="g-0">
            <div class="col">
                <div class="box-wrapper box-documents">
                    <div class="box-content-header">
                        <div class="row p-1">
                            <div class="col">
                                <mat-card-title class="d-flex justify-content-between">
                                    <span>
                                        <i class="fa-solid fa-folder-tree"></i>
                                        <span>Documente</span>
                                    </span>
                                    <span *ngIf="permissionsService.hasPermission('solicitari.view.add-documents') && 
                                        (permissionsService.hasPermission('solicitari.functie-operator.manager') || permissionsService.isUserAdmin) 
                                        && !isEvaluatoriDone()">
                                        <button type="button" class="btn btn-primary" (click)="openAddDocumentDialog()">
                                            <i class="fa-solid fa-plus"></i>
                                            Adaugă Document
                                        </button>
                                    </span>
                                </mat-card-title>
                            </div>
                        </div>
                    </div>

                    <div class="box-content-body gap-5">
                        <div class="text-center m-2" *ngIf="isEvaluatorDone()">
                            <h2 class="m-0">Ați terminat de evaluat solicitarea</h2>
                            <h3 class="m-0">Status: <strong>{{ cerere?.status_evaluare }}</strong></h3>
                        </div>
                        <div *ngFor="let document of documenteCetatean; let i = index">
                            <hr class="my-3">
                            <div class="row align-items-center pe-2">
                                <div class="col-auto">
                                    <img src="assets/icons/doc-icon.svg" alt="Document">
                                </div>
                                <div class="col">
                                    <div class="cerere-title">{{ document.doc_type }}</div>
                                    <p>Denumire document: {{ document.old_name }}</p>
                                </div>
                                <div class="col-auto ms-auto d-flex flex-column flex-lg-row align-items-center gap-5 ">
                                    <ng-container 
                                        *ngIf="cerere?.type_slug == 'depunere_proiect' && 
                                        (
                                            cerere?.user_assigned_id == userId 
                                        ||
                                            (
                                                permissionsService.isUserAdmin == true 
                                                || userId == cerere?.operator_assigned_id_1 
                                                || userId == cerere?.operator_assigned_id_2 
                                            ) 
                                        )
                                        "
                                        >
                                        <div *ngIf="document.cancel_date === null && document.validated_date === null" class="me-lg-5 me-0">
                                            <div class="d-flex flex-column">
                                                <div class="d-flex justify-content-center">
                                                    <button *ngIf="(permissionsService.hasPermission('solicitari.view.validate-document')
                                                         && 
                                                        (
                                                            ( 
                                                                document.doc_type == 'Anexa 2' 
                                                                && permissionsService.hasPermission('solicitari.functie-operator.evaluator-tehnic')
                                                                && cerere?.operator_assigned_id_2 == userId
                                                            )
                                                            || 
                                                            ( 
                                                                document.doc_type !== 'Anexa 2' 
                                                                && permissionsService.hasPermission('solicitari.functie-operator.evaluator') 
                                                                && cerere?.operator_assigned_id_1 == userId
                                                            )
                                                        )
                                                    )
                                                    " 

                                                        class="btn btn-success ms-1 d-flex align-items-center gap-1" style="border-radius: 4px"
                                                        (click)="validateDocument(document.id)">
                                                        <i class="fa-solid fa-check"></i>Validare
                                                    </button>
                                                </div>
                                                <hr>
                                                <div class="d-flex justify-content-center">
                                                    <button *ngIf="(permissionsService.hasPermission('solicitari.view.invalidate-document')
                                                        && 
                                                        (
                                                            ( 
                                                                document.doc_type == 'Anexa 2' 
                                                                && permissionsService.hasPermission('solicitari.functie-operator.evaluator-tehnic')
                                                                && cerere?.operator_assigned_id_2 == userId
                                                            )
                                                            || 
                                                            ( 
                                                                document.doc_type !== 'Anexa 2' 
                                                                && permissionsService.hasPermission('solicitari.functie-operator.evaluator') 
                                                                && cerere?.operator_assigned_id_1 == userId
                                                            )
                                                        )   
                                                        )"
                                                        class="btn btn-danger ms-1 d-flex align-items-center gap-1" style="border-radius: 4px"
                                                        (click)="invalidateDocument(document.id)">
                                                        <i class="fa-solid fa-xmark"></i>Invalidare
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="document.cancel_date === null && document.validated_date !== null" class="me-lg-5 me-0">
                                            <div class="d-flex flex-column">
                                                <div *ngIf="
                                                    (
                                                        permissionsService.hasPermission('solicitari.functie-operator.manager') 
                                                        && userId == cerere?.user_assigned_id
                                                    )
                                                    || permissionsService.isUserAdmin
                                                    || (
                                                        document.doc_type == 'Anexa 2'
                                                        && userId == cerere?.operator_assigned_id_2
                                                    )
                                                    || (
                                                        document.doc_type != 'Anexa 2'
                                                        && userId == cerere?.operator_assigned_id_1
                                                    )" 
                                                    class="d-flex align-items-center justify-content-between gap-4">
                                                    <div>
                                                        <div class="d-flex align-items-center">
                                                            <i class="fa-solid fa-check me-2"></i>
                                                            <span>Validat</span>
                                                        </div>
                                                        <div class="d-flex align-items-center">
                                                            <i class="fa-regular fa-user me-2"></i>        
                                                            <span>{{document.validated_user_name}}</span>
                                                        </div>
                                                        <div class="d-flex align-items-center">
                                                            <i class="fa-regular fa-clock me-2"></i>        
                                                            <span>{{this.formatRomanianDate(document.validated_date)}}</span>
                                                        </div>
                                                    </div>
                                                    <button *ngIf="(permissionsService.hasPermission('solicitari.view.rollback-validate-document')
                                                        && (
                                                            (
                                                                permissionsService.hasPermission('solicitari.functie-operator.manager')
                                                                && userId == cerere?.user_assigned_id
                                                            ) 
                                                            
                                                            || permissionsService.isUserAdmin
                                                        )
                                                        && (
                                                            (
                                                                document.doc_type == 'Anexa 2' 
                                                                && cerere?.evaluated_operator_2 == 0
                                                            ) 
                                                            ||
                                                            (   
                                                                document.doc_type != 'Anexa 2' 
                                                                && cerere?.evaluated_operator_1 == 0
                                                            )
                                                        )
                                                    )" 
                                                        class="btn btn-warning ms-1" style="border-radius: 4px" (click)="resetValidateDocument(document.id)">
                                                        RESET
                                                    </button>
                                                </div>
                                                <!-- <hr>
                                                <div class="d-flex justify-content-center">
                                                    <button *ngIf="(permissionsService.hasPermission('solicitari.view.cancel-document')
                                                        && 
                                                        (
                                                            ( 
                                                                document.doc_type == 'Anexa 2' 
                                                                && permissionsService.hasPermission('solicitari.functie-operator.evaluator-tehnic')
                                                                && cerere?.operator_assigned_id_2 == userId
                                                                && cerere?.evaluated_operator_2 != 0
                                                            )
                                                            || 
                                                            ( 
                                                                document.doc_type !== 'Anexa 2' 
                                                                && permissionsService.hasPermission('solicitari.functie-operator.evaluator') 
                                                                && cerere?.operator_assigned_id_1 == userId
                                                                && cerere?.evaluated_operator_1 != 0
                                                            )
                                                        )
                                                        )" 
                                                        class="cancel btn btn-danger ms-1 d-flex align-items-center gap-1" style="border-radius: 4px"
                                                        (click)="cancelDocument(document.id)">
                                                        <i class="fa-solid fa-xmark"></i>Anulare
                                                    </button>
                                                </div> -->
                                            </div>
                                        </div>
                                        <div *ngIf="document.cancel_date !== null && document.validated_date === null" class="me-lg-5 me-0">
                                            <div class="d-flex flex-column">
                                                <div *ngIf="
                                                    (
                                                        permissionsService.hasPermission('solicitari.functie-operator.manager') 
                                                        && userId == cerere?.user_assigned_id
                                                    )
                                                    || permissionsService.isUserAdmin
                                                    || (
                                                        document.doc_type == 'Anexa 2'
                                                        && userId == cerere?.operator_assigned_id_2
                                                    )
                                                    || (
                                                        document.doc_type != 'Anexa 2'
                                                        && userId == cerere?.operator_assigned_id_1
                                                    )" 
                                                    class="d-flex align-items-center justify-content-between gap-4">
                                                    <div>
                                                        <div class="d-flex align-items-center">
                                                            <i class="fa-solid fa-xmark me-2 red-text"></i>          
                                                            <span>Anulat</span>
                                                        </div>
                                                        <div class="d-flex align-items-center">
                                                            <i class="fa-solid fa-xmark me-2 red-text"></i>          
                                                            <span>{{document.cancel_user_name}}</span>
                                                        </div>
                                                        <div class="d-flex align-items-center">
                                                            <i class="fa-regular fa-clock me-2 red-text"></i>        
                                                            <span>{{this.formatRomanianDate(document.cancel_date)}}</span>
                                                        </div>
                                                        <div *ngIf="document.cancel_obs !== null" class="d-flex align-items-center">
                                                            <i class="fa-regular fa-comments me-2 red-text"></i>       
                                                            <span>{{document.cancel_obs}}</span>
                                                        </div>
                                                    </div>
                                                    <button *ngIf="(permissionsService.hasPermission('solicitari.view.rollback-invalidate-document')
                                                        && (
                                                            (
                                                                permissionsService.hasPermission('solicitari.functie-operator.manager')
                                                                && userId == cerere?.user_assigned_id
                                                            ) 
                                                            
                                                            || permissionsService.isUserAdmin
                                                        )
                                                        && (
                                                            (
                                                                document.doc_type == 'Anexa 2' 
                                                                && cerere?.evaluated_operator_2 == 0
                                                            ) 
                                                            ||
                                                            (   
                                                                document.doc_type !== 'Anexa 2' 
                                                                && cerere?.evaluated_operator_1 == 0
                                                            )
                                                        )
                                                        )" 
                                                        class="btn btn-warning ms-1" style="border-radius: 4px" (click)="resetInvalidateDocument(document.id)">
                                                        RESET
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div *ngIf="document.cancel_date !== null && document.validated_date !== null" class="me-lg-5 me-0">
                                            <div class="d-flex flex-column">
                                                <div class="d-flex align-items-center justify-content-between gap-4">
                                                    <div>
                                                        <div class="d-flex align-items-center">
                                                            <i class="fa-regular fa-check me-2"></i>        
                                                            <span>Aprobat</span>
                                                        </div>
                                                        <div class="d-flex align-items-center">
                                                            <i class="fa-regular fa-user me-2"></i>        
                                                            <span>{{document.validated_user_name}}</span>
                                                        </div>
                                                        
                                                        <div class="d-flex align-items-center">
                                                            <i class="fa-regular fa-clock me-2"></i>        
                                                            <span>{{this.formatRomanianDate(document.validated_date)}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr>
                                                <div class="d-flex align-items-center justify-content-between gap-4">
                                                    <div>
                                                        <div class="d-flex align-items-center">
                                                            <i class="fa-solid fa-xmark me-2 red-text"></i>          
                                                            <span>Anulat</span>
                                                        </div>
                                                        <div class="d-flex align-items-center">
                                                            <i class="fa-solid fa-xmark me-2 red-text"></i>          
                                                            <span>{{document.cancel_user_name}}</span>
                                                        </div>
                                                        <div class="d-flex align-items-center">
                                                            <i class="fa-regular fa-clock me-2 red-text"></i>        
                                                            <span>{{this.formatRomanianDate(document.cancel_date)}}</span>
                                                        </div>
                                                        <div *ngIf="document.cancel_obs !== null" class="d-flex align-items-center">
                                                            <i class="fa-regular fa-comments me-2 red-text"></i>       
                                                            <span>{{document.cancel_obs}}</span>
                                                        </div>
                                                    </div>
                                                    <button *ngIf="(permissionsService.hasPermission('solicitari.view.rollback-cancel-document')
                                                        && permissionsService.hasPermission('solicitari.functie-operator.manager')
                                                        && userId == cerere?.user_assigned_id 
                                                        && document.cancel_user_id != null
                                                        && (
                                                            (
                                                                document.doc_type == 'Anexa 2' 
                                                                && cerere?.evaluated_operator_2 == 0
                                                            ) 
                                                            ||
                                                            (   
                                                                document.doc_type !== 'Anexa 2' 
                                                                && cerere?.evaluated_operator_1 == 0
                                                            )
                                                        )
                                                        )" 
                                                        class="btn btn-warning ms-1" style="border-radius: 4px" (click)="resetCancelDocument(document.id)">
                                                        RESET
                                                    </button>
                                                </div>
                                            </div>
                                        </div> -->
                                    </ng-container>
                                    <button *ngIf="permissionsService.hasPermission('solicitari.view.download-document') &&
                                        (permissionsService.hasPermission('solicitari.functie-operator.manager') ||
                                        (userId == cerere?.operator_assigned_id_1 || userId == cerere?.operator_assigned_id_2))"
                                        type="button" class="btn btn-light open-btn" (click)="downloadDocument(document)">
                                        <img src="/assets/icons/view.svg" alt="VIZUALIZAȚI" />
                                        <span class="view-btn-text">VIZUALIZAȚI</span>
                                    </button>
                                </div>
                            </div>
                            <hr class="my-3">
                        </div>
                        <!-- areAllDocumentsProcessed(documenteCetatean) && !isEvaluatorDone() -->
                        <!-- here we need to establish if evaluator has permission, is allocated, all documents are processed and the final evaluation was not finished -->
                        <div *ngIf="(
                            (
                                permissionsService.hasPermission('solicitari.functie-operator.evaluator') 
                                && userId == cerere?.operator_assigned_id_1
                                && cerere?.evaluated_all_documents_administrative == true
                                && cerere?.evaluated_operator_1 == 0
                            )
                            ||
                            (
                                permissionsService.hasPermission('solicitari.functie-operator.evaluator-tehnic') 
                                && userId == cerere?.operator_assigned_id_2
                                && cerere?.evaluated_all_documents_technical == true
                                && cerere?.evaluated_operator_2 == 0
                            )

                        )" class="d-grid col-6 mx-auto my-4">
                            <button type="button" class="btn btn-success" (click)="endEvaluation()">
                                <h2 class="m-0">CONFIRMĂ EVALUAREA</h2>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="g-0">
            <div class="col">
                <div class="box-wrapper box-mesagerie">
                    <div class="box-content-header">
                        <div class="row p-1">
                            <div class="col">
                                <mat-card-title>
                                    <i class="fa-brands fa-rocketchat"></i>
                                    <span>Mesagerie</span>
                                </mat-card-title>
                            </div>
                            <div class="col d-flex justify-content-end">
                                <div>
                                    <!-- Opetator admin || coordonator asignat -->
                                    <!-- or evaluator alocat -->
                                    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" (change)="onMessagingChange($event)" value="internalMsg">
                                        <mat-button-toggle value="toCetatean" 
                                        *ngIf="permissionsService.isUserAdmin == true 
                                        || (permissionsService.hasPermission('solicitari.functie-operator.manager') 
                                            && userId == this.cerere?.user_assigned_id)
                                        || ( 
                                            userId == this.cerere?.operator_assigned_id_2
                                            || userId == this.cerere?.operator_assigned_id_1
                                        )
                                        ">
                                        Către UAT
                                    </mat-button-toggle>
                                        <mat-button-toggle value="internalMsg">Mesaj Intern</mat-button-toggle>
                                        <mat-button-toggle value="nota">Notă</mat-button-toggle>
                                    </mat-button-toggle-group>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="box-content-body">
                        <div class="row py-2" *ngIf="selectedMessagingToggle == 'toCetatean'">
                            <div class="col">
                                <div class="fw-bold p-1">Transmiteți un mesaj către UAT</div>
                            </div>
                        </div>
                        <div class="row py-2" *ngIf="selectedMessagingToggle == 'internalMsg'">
                            <div class="col">
                                <div class="fw-bold p-1">Adăugați un mesaj</div>
                            </div>
                        </div>
                        <div class="row py-2" *ngIf="selectedMessagingToggle == 'nota'">
                            <div class="col">
                                <div class="fw-bold p-1">Adăugați o notă</div>
                            </div>
                        </div>

                        <form [formGroup]="sendMessageCetateanForm" (ngSubmit)="submitMessage('cetatean')" 
                            *ngIf="selectedMessagingToggle == 'toCetatean' && (
                                        permissionsService.isUserAdmin 
                                        || (permissionsService.hasPermission('solicitari.functie-operator.manager') && userId == this.cerere?.user_assigned_id)
                                        || (userId == this.cerere?.operator_assigned_id_1 || userId == this.cerere?.operator_assigned_id_2)
                                    )">
                            <div class="row p-1">
                                <div class="col">
                                    <angular-editor formControlName="message" [config]="editorConfig"></angular-editor>
                                </div>
                            </div>

                            <div class="solicitare-card__upload mx-2 mt-3">
                                <div class="solicitare-card__upload-header">
                                    <p class="upload-text">Fotografii/Documente</p>
                                    <button class="upload-btn" (click)="fileInputCetatean.click()" type="button">
                                        <mat-icon class="upload-icon">cloud_upload</mat-icon>
                                        ÎNCARCĂ
                                    </button>
                                </div>
                                <div class="solicitare-card__upload-files" *ngIf="sendMessageCetateanForm.value.files.length > 0 && !uploading.file">
                                    <div class="solicitare-card__upload-file" *ngFor="let uploadedFile of sendMessageCetateanForm.value.files; let i = index">
                                        <span class="solicitare-card__upload-file-name">
                                            {{ (uploadedFile.original_name.length > 30) ? (uploadedFile.original_name | slice: 0:30) + '..' : (uploadedFile.original_name)
                                            }}
                                        </span>
                                        <div>
                                            <button class="solicitare-card__upload-file-delete" type="button" matTooltip="Șterge fișierul încărcat" (click)="removeFileCetatean(i)">
                                                <img class="btn-img" src="../../../assets/icons/delete.svg" alt="" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <mat-spinner diameter="32" style="margin-right: 8px; margin-top: 4px" *ngIf="uploading.file"></mat-spinner>

                                <input type="file" accept="image/png, image/jpeg, image/gif, image/jpg, application/pdf" id="upload-file-input-0" #fileInputCetatean
                                    name="fileInputCetatean" (change)="addFileCetatean($event, 'file')" multiple style="display: none">
                            </div>

                            <div class="row mt-3">
                                <div class="col d-flex justify-content-between">
                                    <div class="p-1">
                                        <button class="btn btn-light upload-btn" type="button" (click)="openSabloane()">
                                            <span class="upload__text">Șabloane</span>
                                        </button>
                                    </div>

                                    <div class="p-1">
                                        <button [disabled]="!sendMessageCetateanForm.valid" class="btn btn-light upload-btn" type="submit">
                                            <span class="upload__text">TRIMITE</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form [formGroup]="sendMessageInternForm" (ngSubmit)="submitMessage('intern')" *ngIf="selectedMessagingToggle == 'internalMsg'">
                            <div class="row p-1">
                                <div class="col">
                                    <angular-editor formControlName="message" [config]="editorConfig"></angular-editor>
                                </div>
                            </div>

                            <div class="row p-1">
                                <mat-form-field class="w-100">
                                    <mat-label>Adaugă coleg</mat-label>
                                    <mat-chip-grid #chipGrid aria-label="Selectare colegi">
                                        <mat-chip-row *ngFor="let coleague of coleagues" (removed)="removeColeague(coleague)">
                                            {{coleague.name}}
                                            <button matChipRemove [attr.aria-label]="'remove ' + coleague.name">
                                                <mat-icon>cancel</mat-icon>
                                            </button>
                                        </mat-chip-row>
                                    </mat-chip-grid>
                                    <input placeholder="Coleg nou..." #coleagueInput [formControl]="coleaguesCtrl" [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addColeague($event)"
                                    />
                                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedColeagues($event)">
                                        <mat-option *ngFor="let coleague of filteredColeagues | async" [value]="coleague">
                                            {{coleague.name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>

                            <div class="solicitare-card__upload mx-2">
                                <div class="solicitare-card__upload-header">
                                    <p class="upload-text">Fotografii/Documente</p>
                                    <button class="upload-btn" (click)="fileInputIntern.click()" type="button">
                                        <mat-icon class="upload-icon">cloud_upload</mat-icon>
                                        ÎNCARCĂ
                                    </button>
                                </div>
                                <div class="solicitare-card__upload-files" *ngIf="sendMessageInternForm.value.files.length > 0 && !uploading.file">
                                    <div class="solicitare-card__upload-file" *ngFor="let uploadedFile of sendMessageInternForm.value.files; let i = index">
                                        <span class="solicitare-card__upload-file-name">
                                            {{ (uploadedFile.original_name.length > 30) ? (uploadedFile.original_name | slice: 0:30) + '..' : (uploadedFile.original_name)
                                            }}
                                        </span>
                                        <div>
                                            <button class="solicitare-card__upload-file-delete" type="button" matTooltip="Șterge fișierul încărcat" (click)="removeFileIntern(i)">
                                                <img class="btn-img" src="../../../assets/icons/delete.svg" alt="" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <mat-spinner diameter="32" style="margin-right: 8px; margin-top: 4px" *ngIf="uploading.file"></mat-spinner>

                                <input type="file" accept="image/png, image/jpeg, image/gif, image/jpg, application/pdf" id="upload-file-input-0" #fileInputIntern
                                    name="fileInputIntern" (change)="addFileIntern($event, 'file')" multiple style="display: none">
                            </div>

                            <div class="col d-flex justify-content-end">
                                <button [disabled]="!sendMessageInternForm.valid" class="btn btn-light upload-btn m-3" type="submit">
                                    <span class="upload__text">TRIMITE</span>
                                </button>
                            </div>

                        </form>

                        <form [formGroup]="sendMessageNotitaForm" (ngSubmit)="submitMessage('nota')" *ngIf="selectedMessagingToggle == 'nota'">
                            <div class="row p-1">
                                <div class="col">
                                    <angular-editor formControlName="message" [config]="editorConfig"></angular-editor>
                                </div>
                            </div>

                            <div class="solicitare-card__upload mx-2 mt-3">
                                <div class="solicitare-card__upload-header">
                                    <p class="upload-text">Fotografii/Documente</p>
                                    <button class="upload-btn" (click)="fileInputNotita.click()" type="button">
                                        <mat-icon class="upload-icon">cloud_upload</mat-icon>
                                        ÎNCARCĂ

                                    </button>
                                </div>
                                <div class="solicitare-card__upload-files" *ngIf="sendMessageNotitaForm.value.files.length > 0 && !uploading.file">
                                    <div class="solicitare-card__upload-file" *ngFor="let uploadedFile of sendMessageNotitaForm.value.files; let i = index">
                                        <span class="solicitare-card__upload-file-name">
                                            {{ (uploadedFile.original_name.length > 30) ? (uploadedFile.original_name | slice: 0:30) + '..' : (uploadedFile.original_name)
                                            }}
                                        </span>
                                        <div>
                                            <button class="solicitare-card__upload-file-delete" type="button" matTooltip="Șterge fișierul încărcat" (click)="removeFileNota(i)">
                                                <img class="btn-img" src="../../../assets/icons/delete.svg" alt="" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <mat-spinner diameter="32" style="margin-right: 8px; margin-top: 4px" *ngIf="uploading.file"></mat-spinner>

                                <input type="file" accept="image/png, image/jpeg, image/gif, image/jpg, application/pdf" id="upload-file-input-0" #fileInputNotita
                                    name="fileInputNotita" (change)="addFileNotita($event, 'file')" multiple style="display: none">

                            </div>
                            <div class="col d-flex justify-content-end">
                                <button [disabled]="!sendMessageNotitaForm.valid" class="btn btn-light upload-btn m-3" type="submit">
                                    <span class="upload__text">TRIMITE</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <app-cereri-istoric [user]="user" [istoric]="istoric" [id_cerere]="cerere?.id" [notifyIds]="cerere?.historyNotify"
            (requestInfoUpdated)="getRequestInfo()"></app-cereri-istoric>

    </div>
    <div class="col-4 ">
        <div class="g-0">
            <div class="col responsive-box-info">
                <div class="box-wrapper box-info">
                    <div class="box-content-header">
                        <div class="row p-1">
                            <div class="col">
                                <mat-card-title>
                                    <i class="fa-solid fa-circle-info"></i>
                                    <span>Info solicitare</span>
                                </mat-card-title>
                            </div>
                        </div>
                    </div>
                    <div class="box-content-body">
                        <table class="table table-condensed table-wrapper-box">
                            <tbody>
                                <tr>
                                    <td width="40%" class="text-center">
                                        <strong>Tip</strong>
                                    </td>
                                    <td class="text-left">{{cerere?.type_name}}</td>
                                </tr>
                                <tr>
                                    <td width="40%" class="text-center">
                                        <strong>Prioritate</strong>
                                    </td>
                                    <td class="text-left">
                                        <span class="badge" [ngStyle]="{
                                                'background-color': cerere?.priority_bg_color,
                                                color: '#ffffff'
                                              }">
                                            {{ cerere?.priority_name }}
                                        </span>
                                </tr>
                                <tr>
                                    <td width="40%" class="text-center">
                                        <strong>Status</strong>
                                    </td>
                                    <td class="text-left">
                                        <span class="badge" [ngStyle]="{
                                                'background-color': cerere?.status_bg_color,
                                                color: '#ffffff'
                                              }">
                                            {{ cerere?.status_name }}
                                        </span>
                                </tr>
                                <tr>
                                    <td width="40%" class="text-center">
                                        <strong>Sla</strong>
                                    </td>
                                    <td class="text-left">
                                        <div [innerHTML]="safeHtml"></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="40%" class="text-center">
                                        <strong>Instituție/Direcție</strong>
                                    </td>
                                    <td class="text-left">{{ cerere?.directie || 'n/a' }}</td>
                                </tr>
                                <tr>
                                    <td width="40%" class="text-center">
                                        <strong>Serviciu/Birou</strong>
                                    </td>
                                    <td class="text-left">{{ cerere?.compartiment || 'n/a' }}</td>
                                </tr>
                                <tr>
                                    <td width="40%" class="text-center">
                                        <strong>Operator asignat</strong>
                                    </td>
                                    <td class="text-left">{{ cerere?.user_assigned }}</td>
                                </tr>
                                <ng-container *ngIf="permissionsService.hasPermission('solicitari.functie-operator.manager') ||
                                    userId == cerere?.operator_assigned_id_1">
                                    <tr>
                                        <td width="40%" class="text-center">
                                            <strong>Evaluator Administrativ</strong>
                                        </td>
                                        <td *ngIf="cerere?.operator_assigned_id_1 != null" class="text-left">
                                            {{ cerere?.operator_assigned_1 }}
                                            <i *ngIf="permissionsService.hasPermission('solicitari.functie-operator.manager')
                                                && cerere?.evaluated_operator_1 == 0"
                                                style="cursor: pointer;" class="fa fa-pencil text-warning" (click)="openAddEvaluatorDialog(1, 2)">
                                            </i>
                                        </td>
                                        <td *ngIf="cerere?.operator_assigned_id_1 == null">
                                            <button *ngIf="permissionsService.hasPermission('solicitari.functie-operator.manager')" 
                                                type="button" class="btn btn-sm btn-primary" (click)="openAddEvaluatorDialog(1, 1)">
                                                <i class="fa-solid fa-plus"></i>
                                                Adaugă
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="40%" class="text-center">
                                            <strong>Status Evaluator Administrativ</strong>
                                        </td>
                                        <ng-container *ngIf="cerere?.operator_assigned_id_1 != null">
                                            <td *ngIf="cerere?.evaluated_operator_1 == 0" class="text-left">
                                                În așteptare evaluare
                                            </td>
                                            <td *ngIf="cerere?.evaluated_operator_1 != 0" class="text-left">
                                                <span *ngIf="cerere?.evaluated_operator_1 == 1">Eligibil</span>
                                                <span *ngIf="cerere?.evaluated_operator_1 == 2">Ne-Eligibil</span>
                                                <button *ngIf="userId == cerere?.user_assigned_id || permissionsService.isUserAdmin" class="btn btn-warning ms-2"
                                                    (click)="resetEndEvaluation(this.cerere?.operator_assigned_id_1)">
                                                    RESET
                                                </button>
                                            </td>
                                        </ng-container>
                                        <ng-container *ngIf="cerere?.operator_assigned_id_1 == null">
                                            <td *ngIf="cerere?.evaluated_operator_1 == 0" class="text-left">
                                                În așteptare evaluator
                                            </td>
                                        </ng-container>
                                    </tr>
                                </ng-container>
                                <ng-container *ngIf="permissionsService.hasPermission('solicitari.functie-operator.manager') ||
                                    userId == cerere?.operator_assigned_id_2">
                                    <tr>
                                        <td width="40%" class="text-center">
                                            <strong>Evaluator Tehnic</strong>
                                        </td>
                                        <td *ngIf="cerere?.operator_assigned_id_2 != null" class="text-left">
                                            {{ cerere?.operator_assigned_2 }}
                                            <i *ngIf="permissionsService.hasPermission('solicitari.functie-operator.manager')
                                                && cerere?.evaluated_operator_2 == 0"
                                                style="cursor: pointer;" class="fa fa-pencil text-warning" (click)="openAddEvaluatorDialog(2, 2)">
                                            </i>
                                        </td>
                                        <td *ngIf="cerere?.operator_assigned_id_2 == null">
                                            <button *ngIf="permissionsService.hasPermission('solicitari.functie-operator.manager')" 
                                                type="button" class="btn btn-sm btn-primary" (click)="openAddEvaluatorDialog(2, 1)">
                                                <i class="fa-solid fa-plus"></i>
                                                Adaugă
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="40%" class="text-center">
                                            <strong>Status Evaluator Tehnic</strong>
                                        </td>
                                        <ng-container *ngIf="cerere?.operator_assigned_id_2 != null">
                                            <td *ngIf="cerere?.evaluated_operator_2 == 0" class="text-left">
                                                În așteptare evaluare
                                            </td>
                                            <td *ngIf="cerere?.evaluated_operator_2 != 0" class="text-left">
                                                <span *ngIf="cerere?.evaluated_operator_2 == 1">Eligibil</span>
                                                <span *ngIf="cerere?.evaluated_operator_2 == 2">Ne-Eligibil</span>
                                                <button *ngIf="userId == cerere?.user_assigned_id || permissionsService.isUserAdmin" class="btn btn-warning ms-2"
                                                    (click)="resetEndEvaluation(this.cerere?.operator_assigned_id_2)">
                                                    RESET
                                                </button>
                                            </td>
                                        </ng-container>
                                        <ng-container *ngIf="cerere?.operator_assigned_id_2 == null">
                                            <td *ngIf="cerere?.evaluated_operator_2 == 0" class="text-left">
                                                În așteptare evaluator
                                            </td>
                                        </ng-container>
                                    </tr>
                                </ng-container>
                                <tr *ngIf="cerere?.check_uat_inregistrat !== undefined">
                                    <td width="40%" class="text-center">
                                        <strong>Solicitare depusă</strong>
                                    </td>
                                    <td *ngIf="cerere?.check_uat_inregistrat" class="text-left"><i class="fa-regular fa-square-check"></i></td>
                                    <td *ngIf="!cerere?.check_uat_inregistrat" class="text-left"><i class="fa-regular fa-square"></i></td>
                                </tr>
                                <tr *ngIf="cerere?.check_documente_incarcate !== undefined">
                                    <td width="40%" class="text-center">
                                        <strong>Documente depuse</strong>
                                    </td>
                                    <td *ngIf="cerere?.check_documente_incarcate" class="text-left"><i class="fa-regular fa-square-check"></i></td>
                                    <td *ngIf="!cerere?.check_documente_incarcate" class="text-left"><i class="fa-regular fa-square"></i></td>
                                </tr>
                                <tr>
                                    <td width="40%" class="text-center">
                                        <strong>Dată solicitare</strong>
                                    </td>
                                    <td class="text-left">{{cerere?.created_at | date: 'dd.MM.yyyy HH:mm'}}</td>
                                </tr>
                                <tr>
                                    <td width="40%" class="text-center">
                                        <strong>Ultima actualizare</strong>
                                    </td>
                                    <td class="text-left">{{cerere?.updated_at | date: 'dd.MM.yyyy HH:mm'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="g-0" *ngIf="permissionsService.hasPermission('solicitari.functie-operator.manager') 
                || permissionsService.isUserAdmin">
            <div class="col">
                <div class="box-wrapper box-actiuni-cerere responsive-box-info">
                    <div class="box-content-header">
                        <div class="row p-1">
                            <div class="col">
                                <mat-card-title>
                                    <i class="fa-solid fa-shuffle"></i>
                                    <span>Acțiuni solicitare</span>
                                </mat-card-title>
                            </div>
                        </div>
                    </div>
                    <div class="box-content-body">
                        <div *ngIf="permissionsService.hasPermission('solicitari.view.preluare-personala')" class="row p-2 d-flex">
                            <div class="w-auto" *ngIf="
                                      (cerere?.user_assigned_id == null ||
                                      cerere?.user_assigned_id !== user.id_user)
                                    ">
                                <button class="btn btn-light upload-btn" type="button" (click)="confirmPreluarePersonala()">
                                    <span class="upload__text">PRELUARE PERSONALĂ</span>
                                </button>
                            </div>
                        </div>
                        <div class="row p-2 d-flex box-content-body-responsive ">
                            <div class="col" [class.hidden]="(cerere?.cancel_type == 1 && cerere?.cancel_date !== null) || cerere?.type_slug === 'suport_tehnic'">
                                <actiuni-cerere-card title="Status validat" description="Nevalidat" iconName="check" [cerere]="cerere" actionText="Validează"
                                    actionType="validate" 
                                    (buttonClicked)="handleButtonClick($event)" (buttonResetClicked)="handleButtonResetClick($event)"></actiuni-cerere-card>
                            </div>
                            <ng-container>
                                <div class="col" *ngIf="cerere?.type_slug === 'depunere_proiect' && cerere?.validated_date === null && (
                                    (cerere?.cancel_type == null && cerere?.finalize_date == null) ||
                                    (cerere?.cancel_type == 1))">
                                    <actiuni-cerere-card title="Invalidare Solicitare" description="Solicitare încă valabilă de validare. Nu invalidați decât dacă este cazul!" iconName="report_problem"
                                        actionText="Invalidează" [cerere]="cerere" actionType="invalidate"
                                        (buttonClicked)="handleButtonClick($event)" (buttonResetClicked)="handleButtonResetClick($event)"></actiuni-cerere-card>
                                </div>
                                <div class="col" *ngIf="cerere?.type_slug === 'depunere_proiect' && (cerere?.validated_date !== null && cerere?.cancel_type == null && cerere?.finalize_date == null) ||
                                    cerere?.cancel_type == 2">
                                    <actiuni-cerere-card title="Clasare" description="Clasare" iconName="report_problem" actionText="Clasare" [cerere]="cerere"
                                        actionType="anulare" 
                                        (buttonClicked)="handleButtonClick($event)" (buttonResetClicked)="handleButtonResetClick($event)"></actiuni-cerere-card>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="cerere?.cancel_type !== null || cerere?.validated_date !== null || cerere?.type_slug === 'suport_tehnic'">
                                <div class="col">
                                    <actiuni-cerere-card title="Status Închidere Lucrare" description="Închidere lucrare doar în momentul în care a fost soluționată solicitarea."
                                        iconName="check" actionText="Închidere lucrare" [cerere]="cerere" actionType="finalizare"
                                        (buttonClicked)="handleButtonClick($event)" (buttonResetClicked)="handleButtonResetClick($event)"></actiuni-cerere-card>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-actualizare-cerere *ngIf="permissionsService.isUserAdmin == true || permissionsService.hasPermission('solicitari.functie-operator.manager')" 
            [data]="data" 
            (cerereChanged)="onCerereChanged($event)" 
            (istoricChanged)="onIstoricChanged($event)"></app-actualizare-cerere>
    </div>
</div>
<div class="section-spinner position-fixed" *ngIf="isLoading">
    <div class="spinner position-absolute top-50 start-50 translate-middle">
        <img src="assets/images/newAppLogo.svg" height="50%" width="50%" alt="Guvernul României" />
        <mat-spinner class="mt-1"></mat-spinner>
        <h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
    </div>
</div>